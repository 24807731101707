import React from 'react';
import { Box, DialogTitle, DialogContent, Typography, Divider, Chip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Level from './Level';

export const levels = [
    { label: "Walker", icon: <CloseIcon />, disabled: false, treshold: [-1, 5000] },
    { label: "Challenger", icon: <CloseIcon />, disabled: false, treshold: [5000, 10000] },
    { label: "Runner", icon: <CloseIcon />, disabled: false, treshold: [10000, 30000] },
    { label: "Legender", icon: <CloseIcon />, disabled: false, treshold: [30000, Infinity] },
];

const FilterDialog = ({
    tabValue,
    stepPrice,
    activity,
    team,
    setOpenFilter,
    categories,
    selectedCategories,
    setSelectedCategories,
    selectedLevel,
    setSelectedLevel,
    maxTeamParticipants,
    startAt,
    selectedMetric, setMetric,
    endAt
}) => {
    const challengePassedDays = Math.floor((endAt - startAt) / (1000 * 60 * 60 * 24))
    const categoriesView = tabValue === 0 && team || tabValue === 1 && !team;
    const firstElement = activity === 'Cycling' ? 'Km' : activity === "Distance" ? 'Meters' : "Steps"
    const metrics = stepPrice !== 0 && stepPrice !== undefined ? [firstElement, 'g Co2 Saved', 'Currency'] : [firstElement, 'g Co2 Saved'];
    const adjustedLevels = tabValue === 0 && team
        ? levels.map(level => ({
            ...level,
            treshold: level.treshold.map(value => value === Infinity ? Infinity : Math.round(value * maxTeamParticipants * challengePassedDays))
        }))
        : levels;

    return (
        <Box
            sx={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: '100%',
                height: '60%',
                backgroundColor: '#222428',
                boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.2)',
                borderTopLeftRadius: '40px',
                borderTopRightRadius: '40px',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <DialogTitle>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant='h5' sx={{ color: 'white', fontWeight: 'bold' }}>Filter by</Typography>
                    <Box onClick={() => setOpenFilter(false)} sx={{ backgroundColor: 'white', borderRadius: '50%', padding: '5px', paddingBottom: '-5px', width: '15px', height: '15px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <CloseIcon />
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent sx={{ flex: 1, overflowY: 'auto', paddingBottom: '100px' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', backgroundColor: '#34373F', paddingTop: '20px', paddingBottom: '20px', borderRadius: '15px' }}>
                    <Typography variant='p' sx={{ color: 'white', paddingLeft: '20px' }}>METRICS</Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', paddingLeft: '10px', paddingTop: '5px' }}>
                        {metrics.map((metric, index) => (
                            <Chip key={index} onClick={() => setMetric(metric)} sx={{ backgroundColor: selectedMetric === metric ? "#222428" : 'transparent', color: selectedMetric === metric ? "#057AF6" : '#fff', paddingLeft: '7px', paddingRight: '7px', borderColor: '#222428', borderWidth: '1px', margin: '3px' }} label={metric} variant='outlined' />
                        ))}
                    </Box>

                    <Divider orientation='horizontal' sx={{ backgroundColor: '#222428', width: '100%', marginTop: '10px' }} />
                    {categoriesView && <Typography variant='p' sx={{ color: 'white', paddingLeft: '20px', marginTop: '10px' }}>CATEGORIES</Typography>}
                    {categoriesView && <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', paddingLeft: '10px', paddingTop: '5px' }}>
                        {categories.map((category, index) => (
                            <Chip key={index} onClick={() => setSelectedCategories((prev) => prev.find(el => el === category) ? [...prev.filter(el => el !== category)] : [...prev, category])} sx={{ backgroundColor: selectedCategories.find(el => el === category) ? "#222428" : 'transparent', color: selectedCategories.find(el => el === category) ? "#057AF6" : '#fff', paddingLeft: '7px', paddingRight: '7px', borderColor: '#222428', borderWidth: '1px', margin: '3px' }} label={category} variant='outlined' />
                        ))}
                    </Box>}

                    {categoriesView && <Divider orientation='horizontal' sx={{ backgroundColor: '#222428', width: '100%', marginTop: '10px' }} />}

                    <Typography variant='p' sx={{ color: 'white', marginTop: '10px', paddingLeft: '20px' }}>LEVELS</Typography>
                    <Box
                        sx={{
                            display: "flex",
                            marginTop: '10px',
                            paddingLeft: '20px',
                            paddingRight: '20px'
                        }}
                    >
                        {adjustedLevels.map(({ label, icon, disabled, treshold }, index) => (
                            <Level selectedLevel={selectedLevel} setSelectedLevel={setSelectedLevel} key={index} label={label} disabled={disabled} icon={icon} treshold={treshold} index={index} />
                        ))}
                    </Box>
                </Box>
            </DialogContent>
        </Box>
    );
};

export default FilterDialog;
