import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import targetRed from "../../../../../../../assets/arrow.png";

const CounterContainer = styled(Box)(({ card }) => ({
    backgroundColor: "#3F434D",
    paddingTop: card ? '6px' : "3px",
    paddingBottom: card ? '6px' : "3px",
    paddingLeft: card ? '6px' : "9px",
    paddingRight: card ? '6px' : "9px",
    borderRadius: "4px",
    height: card ? '16px' : "24px",
    display: "inline-flex",
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "flex-start",
    marginRight: "2px",
    marginBottom: card ? '0px' : '20px',
    width: "auto",
}));

const IconTarget = styled("img")({
    overflow: "hidden",
    height: "15px",
    width: "15px",
    marginRight: '10px',
    marginLeft: '0px',
    objectFit: "cover",
});

const BoxSteps = styled(Box)({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0 2px",
});

const BoxStep = styled(Box)(({ isNegative }) => ({
    padding: "1px",
    marginRight: "3px",
    borderRadius: "3px",
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: isNegative ? "#56a777" : "#e54836",
}));

const CounterText = styled(Typography)({
    alignSelf: "center",
    fontFamily: "Roboto Condensed, sans-serif",
    fontWeight: 700,
    color: "#EEEEEE",
    fontSize: "14px",
    margin:'0 2px',
})

const Counter = ({ target, activity, card }) => {
    const targetDisplayed = Math.abs(target).toString().split("").slice(0, 8);

    return (
        <CounterContainer card={card}>
            <IconTarget src={targetRed} alt="Target Icon" />
            <BoxSteps>
                {targetDisplayed.map((targetNumber, index) => {
                    if (targetNumber === "-") return null;
                    return (
                        <BoxStep key={index} isNegative={target < 0}>
                            <CounterText >{targetNumber}</CounterText>
                        </BoxStep>
                    );
                })}
            </BoxSteps>
            <CounterText>{activity === "Steps" ? "st" : "km"}</CounterText>
        </CounterContainer>
    );
};

export default Counter;
