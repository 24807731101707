import queryString from 'query-string'

import axios from './axios'

export const getActivityTypesReq = async (from, limit) => {
  try {
    const req = await axios.get(`/public/newExplore/activities?from=${from}&limit=${limit}`)
    const res = await req.data

    return res
  } catch (error) {
    console.error({ error })
  }
}

export const getCoursesReq = async (from, limit, searchQuery) => {
  try {
    let url = `/public/newExplore/courses?from=${from}&limit=${limit}&searchQuery=${searchQuery}`

    const req = await axios.get(url)
    const res = req.data

    return res
  } catch (error) {
    console.error({ error })
  }
}

export const getChallangesReq = async (from, limit, search) => {
  try {
    const req = await axios.get(`public/newExplore/challenges?from=${from}&limit=${limit}&search=${search ? search : ''}`)
    const res = await req.data

    return res
  } catch (error) {
    console.error({ error })
  }
}
export const getLeaderboards = async (id) => {
  try {
    const req = await axios.get(`public/newExplore/leaderboards?id=${id}`)
    const res = await req.data

    return res
  } catch (error) {
    console.error({ error })
  }
}
export const getLeaderboardsChallengers = async (id) => {
  try {
    const req = await axios.get(`public/newExplore/leaderboardsChallengers?id=${id}`)
    const res = await req.data

    return res
  } catch (error) {
    console.error({ error })
  }
}
export const getTrainersReq = async (
  from,
  limit,
  selectedActivities = [],
  selectedRange,
  currentPosition,
  searchQuery
) => {
  try {
    const params = { from, limit }
    let queryString = new URLSearchParams(params).toString()

    if (Array.isArray(selectedActivities) && selectedActivities.length > 0) {
      selectedActivities.forEach((activity) => {
        queryString += `&sportType=${activity}`
      })
    }

    queryString += `&distance=${selectedRange}`
    if (currentPosition && currentPosition.latitude && currentPosition.longitude)
      queryString += `&locationLat=${currentPosition.latitude}&locationLng=${currentPosition.longitude}`
    if (searchQuery) queryString += `&searchQuery=${searchQuery}`

    const req = await axios.get(`public/newExplore/trainers?${queryString}`)
    const res = await req.data

    return res
  } catch (error) {
    console.error({ error })
  }
}

export const getClubsReq = async (
  from,
  limit,
  selectedActivities = [],
  selectedRange,
  currentPosition,
  searchQuery
) => {
  try {
    const params = { from, limit }
    let queryString = new URLSearchParams(params).toString()

    if (Array.isArray(selectedActivities) && selectedActivities.length > 0) {
      selectedActivities.forEach((activity) => {
        queryString += `&sportType=${activity}`
      })
    }

    queryString += `&distance=${selectedRange}`
    if (currentPosition && currentPosition.latitude && currentPosition.longitude)
      queryString += `&locationLat=${currentPosition.latitude}&locationLng=${currentPosition.longitude}`
    if (searchQuery) queryString += `&searchQuery=${searchQuery}`

    const req = await axios.get(`public/newExplore/clubs?${queryString}`)
    const res = await req.data

    return res
  } catch (error) {
    console.error({ error })
  }
}

export const getServicesReq = async (from, limit, searchQuery, selectedRange, currentPosition) => {
  try {
    const params = { from, limit }
    let queryString = new URLSearchParams(params).toString()

    queryString += `&distance=${selectedRange}`
    if (currentPosition && currentPosition.latitude && currentPosition.longitude)
      queryString += `&locationLat=${currentPosition.latitude}&locationLng=${currentPosition.longitude}`
    if (searchQuery) queryString += `&searchQuery=${searchQuery}`

    const req = await axios.get(`public/newExplore/services?${queryString}`)
    const res = await req.data

    return res
  } catch (error) {
    console.error({ error })
  }
}

export const getClubsForMapReq = async () => {
  try {
    const req = await axios.get('public/newExplore/clubs-for-map')
    const res = await req.data

    return res
  } catch (error) {
    console.error({ error })
  }
}

export const getTrainersForMapReq = async () => {
  try {
    const req = await axios.get('public/newExplore/trainers-for-map')
    const res = await req.data

    return res
  } catch (error) {
    console.error({ error })
  }
}

export const getClassesReq = async (from, limit, selectedActivities = [], searchQuery, latitude, longitude) => {
  try {
    const params = { from, limit, latitude, longitude }
    let queryString = new URLSearchParams(params).toString()

    if (Array.isArray(selectedActivities) && selectedActivities.length > 0) {
      selectedActivities.forEach((activity) => {
        queryString += `&sportType=${activity}`
      })
    }

    if (searchQuery) queryString += `&searchQuery=${searchQuery}`

    const req = await axios.get(`public/newExplore/classes?${queryString}`)
    const res = await req.data

    return res
  } catch (error) {
    console.error({ error })
  }
}

export const getCompanies = (options = {}) => {
  const query = {}

  Object.keys(options).forEach((key) => {
    if (options[key] !== null && options[key] !== undefined) {
      query[key] = options[key]
    }
  })
  return axios
    .get('/public/explore/mobile/clubs?' + queryString.stringify({ ...query }))
    .then((res) => res.data)
}

export const getTrainers = (options = {}) => {
  const query = {}

  Object.keys(options).forEach((key) => {
    if (options[key] !== null && options[key] !== undefined) {
      query[key] = options[key]
    }
  })
  return axios
    .get('/public/explore/trainers?' + queryString.stringify({ ...query }))
    .then((res) => res.data)
}

export const getServices = (options = {}) => {
  const query = {}

  Object.keys(options).forEach((key) => {
    if (options[key] !== null && options[key] !== undefined) {
      query[key] = options[key]
    }
  })
  return axios
    .get('/public/explore/services?' + queryString.stringify({ ...query }))
    .then((res) => {
      return res.data
    })
}

export const getEvents = (options = {}) => {
  const query = {}

  Object.keys(options).forEach((key) => {
    if (options[key] !== null && options[key] !== undefined) {
      query[key] = options[key]
    }
  })
  return axios.get('/public/explore/events?' + queryString.stringify({ ...query })).then((res) => {
    return res.data
  })
}
