import { useCallback, useState } from "react";
import { getActivityTypesReq } from "../api/explore";

const useActivityTypes = () => {
    const [activityTypes, setActivityTypes] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(false);

    const getActivityTypes = useCallback(async (from = 0, limit = 5) => {
        setLoading(true);
        try {
            const res = await getActivityTypesReq(from, limit);

            setActivityTypes(res.activities);
            setTotalCount(res.count)
        } catch (error) {
            console.error("err: ", error)
        } finally {
            setLoading(false);
        };
    }, []);

    return {
        getActivityTypes,
        activityTypes,
        totalCount,
        loading
    };
};

export default useActivityTypes