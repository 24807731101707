import React from 'react'
import { Card, Typography, Box } from '@mui/material'
import {
  BoxFlexBetween,
  BoxStyle,
  BoxStyle2,
  CardComponent,
  DividerComponent,
  FlexColumn,
  TextStyle,
  BoxIcon,
  IconSize
} from './index.styled'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import { backgroundAssets } from './backgroundAssets'
import moment from 'moment'
import Counter from './Counter'

export const calculateStatus = (startDate, endDate, joined = false) => {
  if (!startDate || !endDate) return 'PENDING'

  const _startDate = moment(startDate)
  const _endDate = moment(endDate)
  const now = moment()

  if (now.isBefore(_startDate)) return 'PENDING'
  if (now.isAfter(_startDate) && now.isBefore(_endDate)) return joined ? 'ONGOING' : 'ACTIVE'
  if (now.isAfter(_endDate)) return 'FINALISED'

  return 'PENDING'
}

const ChallengeCard = ({ challenges, onOpen }) => {
  const status = calculateStatus(challenges.startAt, challenges.endAt)
  return (
    <Card
      sx={CardComponent(
        challenges.imageUrl?.includes('https')
          ? challenges.imageUrl
          : backgroundAssets[challenges.imageUrl]
      )}
      onClick={onOpen}>
      <Box sx={BoxFlexBetween}>
        <Box sx={FlexColumn}>
          {challenges.avatar && (
            <img
              src={challenges.avatar}
              alt="Challenge Avatar"
              width="36px"
              height="36px"
              style={{ margin: 0, padding: 0, borderRadius: '5px' }}
            />
          )}
        </Box>
        <Box sx={BoxStyle}>
          {challenges.rewardAmount > 0 &&
            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: 'bold',
                fontStyle: 'italic',
                backgroundColor: 'rgba(0,0,0,0.4)',
                borderRadius: '5px',
                padding: '5px'
              }}>
              {challenges.rewardAmount > 0 ? `${challenges.rewardAmount} EUR` : 'REWARD'}
            </Typography>}
          <Typography
            sx={{
              fontSize: '12px',
              ...TextStyle,
              borderRadius: '5px',
              backgroundColor:
                status === 'FINALISED' ? '#FF4A4A' :
                  status === 'PENDING' ? '#F79900' :
                    status === 'ACTIVE' ? '#057AF6' :
                      status === 'ONGOING' ? '#01703C' : '#007bff'
            }}>
            {status}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ padding: '2px 10px' }}>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          {challenges.title}
        </Typography>
        <Box sx={BoxStyle2}>
          <Typography variant="caption" sx={BoxIcon}>
            <img
              src="https://cdn4.iconfinder.com/data/icons/simple-goods-services-outline/30/running-512.png"
              width="20px"
              height="20px"
              style={{ filter: 'invert(100%)' }}
            />
            {challenges.steps ?? 0}
            {" "}
            {challenges.metric.activity}
          </Typography>
          <DividerComponent />
          <Typography variant="caption" sx={BoxIcon}>
            <CalendarTodayIcon sx={IconSize} />
            {challenges.isComingSoon ? 'Coming Soon' : challenges.startDate || 'N/A'}
          </Typography>
          <DividerComponent />
          <Typography variant="caption" sx={BoxIcon}>
            <PeopleAltIcon sx={{ color: '#ccc', ...IconSize }} />
            {challenges.participants ?? 0} JOINED
          </Typography>
          {challenges.isTeamOnly && <DividerComponent />}
          {challenges.isTeamOnly && (
            <Typography variant="caption" sx={BoxIcon}>
              <PeopleAltIcon sx={{ color: '#ccc', ...IconSize }} />
              {Object.keys(challenges.teams ?? {}).length} TEAMS
            </Typography>
          )}
        </Box>
      </Box>
    </Card>
  )
}

export default ChallengeCard
