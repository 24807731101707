import { useCallback, useState } from "react";
import { findAllLogsV2 } from "../api/company";

const useAuditLogs = () => {
    const [logs, setLogs] = useState([]);
    const [loading, setLoading] = useState(false);

    const getLogs = useCallback(async (companyId, searchQuery) => {
        setLoading(true);
        try {
            const res = await findAllLogsV2(companyId, searchQuery);

            setLogs(res);
        } catch (error) {
            console.error("err: ", error)
        } finally {
            setLoading(false);
        };
    }, []);

    return {
        getLogs,
        logs,
        loading
    };
};

export default useAuditLogs;