import React, { useEffect, useMemo, useState } from 'react';
import { Avatar, Box, Typography, LinearProgress } from '@mui/material';
import { BoxLeaderBoard } from './index.styled';
import useLeaderboard from '../../../../../../../hooks/useLeaderboards';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CountryCodes from '../../../../../../../utils/CountryCode.json';
import ReactCountryFlag from "react-country-flag"
import { levels } from './Filter';
import FavoriteIcon from '@mui/icons-material/Favorite';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SelectDate from './SelectDate';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Loading from '../../../../../../Payouts/PayoutsTable/Loading';
import CircularProgressWithLabel from './Pregres';
import Icon from './Icons';
import useLeaderboardChallengers from '../../../../../../../hooks/useLeaerboardChallengers';
export const calculateColor = (
    place
) => {


    return '#D5D9DD';
};
export const avatarImage = (image) => {
    const FlagComponent = CountryCodes[image];

    if (FlagComponent) {
        return (
            <Box sx={{
                position: "absolute",
                width: '20px',
                marginTop: '-12px',
                marginLeft: '12px',
                height: '20px',
                borderColor: "transparent",
                overflow: "hidden",
                backgroundColor: "transparent",
                justifyContent: "center",
                alignItems: "center",
            }}>
                <ReactCountryFlag
                    countryCode={`${FlagComponent}`.toUpperCase()}
                    svg
                    style={{
                        width: '100%',
                        height: '100%',
                        borderRadius: '6px'
                    }}
                    title={FlagComponent}
                />
            </Box>
        );
    } else {
        return null;
    }
};
export const formatDate = (date) => `${String(date.getDate()).padStart(2, "0")}-${String(
    date.getMonth() + 1
).padStart(2, "0")}-${date.getFullYear()}`;

export const formatDateView = (_date) => {
    const months = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    return `${_date.getDate()} ${months[_date.getMonth()]}`;
};
const getLeaderboardDaily = (leaderboard, currentDateKey, teamIds) => {

    const dailyArray = currentDateKey === formatDate(new Date()) ? teamIds.map(id => ({
        _id: leaderboard?.allTeams?.[id]._id,
        metricValue: leaderboard?.dailyLeaderboard?.[id] || 0
    })).filter(Boolean) : Object.values(leaderboard?.allTeams || {}).map((team) => {
        if (team.challengers && team.challengers.length > 0) {

            const metricValue = team.challengers.reduce((sum, challenger) => {

                const challengerValue = challenger.dailyMetricValue?.[currentDateKey] ?? 0;
                return sum + challengerValue;
            }, 0);

            return {
                _id: team._id,
                metricValue: metricValue
            };
        }
        return {
            _id: team._id,
            metricValue: 0
        };
    }).filter(Boolean);
    return dailyArray
}
const LeaderboardTeams = ({ challenges, filter, selectedDate, setSelectedDate, refresh, tab, selectedLevel, selectedCategories, openFilter, setOpenTeam, stepPrice, selectedMetric, dailyFilter, setDailyFilter }) => {
    const { leaderboard, getLeaderboard, loading } = useLeaderboard();
    const { leaderboard: leaderboardChallengers, getLeaderboard: getLeaderboardChallengers } = useLeaderboardChallengers();
    const [podium, setPodium] = useState(3)
    const [open, setOpen] = useState(false)
    const [activity, setActivity] = useState('Steps')
    const [progress, setProgress] = useState(challenges.metric?.minimumValue)

    useEffect(() => {
        getLeaderboard(challenges._id);
        getLeaderboardChallengers(challenges._id);
        setActivity(challenges.metric?.activity)
    }, [challenges, refresh]);


    useEffect(() => {
        if (tab === "Pace") {
            setPodium(challenges.podium?.weekly ? challenges.podium.weekly : 1)
        }
        if (tab === "Daily") {
            if (dailyFilter === 'Leaderboard') {
                setPodium(1)
            } else {
                setPodium(challenges.podium?.daily_winners ? challenges.podium.daily_winners : 1)
            }
        }
        if (challenges.podium?.filters && tab) {
            const newPodium = challenges.podium?.filters
            if (tab === "All") {
                if (selectedLevel === '') setPodium(newPodium.legender)
                if (selectedLevel === 'Walker') setPodium(newPodium.walker)
                if (selectedLevel === 'Challenger') setPodium(newPodium.challenger)
                if (selectedLevel === 'Runner') setPodium(newPodium.runner)
                if (selectedLevel === 'Legender') setPodium(newPodium.legender)
            }
        }
    }, [challenges, tab, selectedLevel, dailyFilter])

    const sortedTeams = useMemo(() => {
        if (!leaderboard || !challenges) return [];
        let teamsArray = [];
        const teamIds = Object.keys(leaderboard?.dailyLeaderboard || {});
        const currentDateKey = formatDate(selectedDate) || formatDate(new Date());
        const today = new Date();
        const endAt = new Date(challenges.endAt);
        const yesterday = new Date();
        const beforeYesterday = new Date();
        if (today <= endAt) {
            today.setDate(today.getDate());

            yesterday.setDate(today.getDate() - 1);
            beforeYesterday.setDate(today.getDate() - 2);
        } else {
            today.setDate(endAt.getDate());
            yesterday.setDate(endAt.getDate());
            beforeYesterday.setDate(endAt.getDate() - 1);

        }

        const metricToday = getLeaderboardDaily(leaderboard, formatDate(yesterday), teamIds)
        const metricYesterday = getLeaderboardDaily(leaderboard, formatDate(beforeYesterday), teamIds)


        const metricTodayMap = metricToday.reduce((acc, team) => {
            acc[team._id] = team.metricValue ?? 0;
            return acc;
        }, {});

        const metricYesterdayMap = metricYesterday.reduce((acc, team) => {
            acc[team._id] = team.metricValue ?? 0;
            return acc;
        }, {});
        if (tab === "All") {
            teamsArray = Object.values(leaderboard?.allTeams || {});
        } else if (tab === "Daily") {
            if (dailyFilter === 'Leaderboard') {
                const teamIds = Object.keys(leaderboard?.dailyLeaderboard || {});
                teamsArray = currentDateKey === formatDate(new Date()) ? teamIds.map(id => ({
                    ...leaderboard?.allTeams?.[id],
                    metricValue: leaderboard?.dailyLeaderboard?.[id] || 0
                })).filter(Boolean) : Object.values(leaderboard?.allTeams || {}).map((team) => {

                    if (team.challengers && team.challengers.length > 0) {
                        const validChallengers = team.challengers.filter((challenger) => {
                            return challenger.dailyMetricValue?.[currentDateKey] !== undefined;
                        });

                        if (validChallengers.length > 0) {
                            const metricValue = validChallengers.reduce((sum, challenger) => {
                                const challengerValue = challenger.dailyMetricValue?.[currentDateKey] ?? 0;
                                return sum + challengerValue;
                            }, 0);

                            return {
                                ...team,
                                metricValue: metricValue
                            };
                        }
                    }
                    return null;
                }).filter(Boolean);
                if (currentDateKey === formatDate(new Date())) {
                    const today = new Date();
                    const endAt = new Date(challenges.endAt);

                    const yesterday = new Date();
                    const beforeYesterday = new Date();

                    if (today <= endAt) {
                        today.setDate(today.getDate());

                        yesterday.setDate(today.getDate() - 1);
                        beforeYesterday.setDate(today.getDate() - 2);
                    } else {
                        today.setDate(endAt.getDate());
                        yesterday.setDate(endAt.getDate());
                        beforeYesterday.setDate(endAt.getDate() - 1);

                    }
                    const metricToday = getLeaderboardDaily(leaderboard, formatDate(yesterday), teamIds)
                    const metricYesterday = getLeaderboardDaily(leaderboard, formatDate(beforeYesterday), teamIds)
                    const metricTodayMap = metricToday.reduce((acc, team) => {
                        acc[team._id] = team.metricValue ?? 0;
                        return acc;
                    }, {});

                    const metricYesterdayMap = metricYesterday.reduce((acc, team) => {
                        acc[team._id] = team.metricValue ?? 0;
                        return acc;
                    }, {});
                    teamsArray = teamsArray.map(team => {
                        const metricValueToday = metricTodayMap[team._id] ?? 0;
                        const metricValueYesterday = metricYesterdayMap[team._id] ?? 0;
                        const metricDifference = metricValueToday - metricValueYesterday;
                        let metricPercentageChange = 0;
                        if (metricValueYesterday !== 0) {
                            metricPercentageChange = (metricDifference / metricValueYesterday) * 100;
                        } else if (metricValueToday !== 0) {
                            metricPercentageChange = 100;
                        }

                        return {
                            ...team,
                            metricDifference,
                            metricPercentageChange: metricPercentageChange.toFixed(2)
                        };
                    });
                } else {
                    const today = selectedDate
                    const yesterday = new Date()
                    yesterday.setDate(today.getDate() - 1);

                    const metricToday = getLeaderboardDaily(leaderboard, currentDateKey, teamIds)
                    const metricYesterday = getLeaderboardDaily(leaderboard, formatDate(yesterday), teamIds)
                    const metricTodayMap = metricToday.reduce((acc, team) => {
                        acc[team._id] = team.metricValue ?? 0;
                        return acc;
                    }, {});

                    const metricYesterdayMap = metricYesterday.reduce((acc, team) => {
                        acc[team._id] = team.metricValue ?? 0;
                        return acc;
                    }, {});
                    teamsArray = teamsArray.map(team => {
                        const metricValueToday = metricTodayMap[team._id] ?? 0;
                        const metricValueYesterday = metricYesterdayMap[team._id] ?? 0;
                        const metricDifference = metricValueToday - metricValueYesterday;
                        let metricPercentageChange = 0;
                        if (metricValueYesterday !== 0) {
                            metricPercentageChange = (metricDifference / metricValueYesterday) * 100;
                        } else if (metricValueToday !== 0) {
                            metricPercentageChange = 100;
                        }

                        return {
                            ...team,
                            metricDifference,
                            metricPercentageChange: metricPercentageChange.toFixed(2)
                        };
                    });
                }
            } else {
                const teamIds = Object.keys(leaderboard?.dailyTrophies || {});
                teamsArray = teamIds.map(id => ({
                    ...leaderboard?.allTeams?.[id],
                    metricValue: leaderboard?.dailyTrophies?.[id] || 0
                })).filter(Boolean);
            }
        } else if (tab === "Pace") {
            const teamIds = Object.keys(leaderboard?.paceLeaderboard || {});
            teamsArray = teamIds.map(id => ({
                ...leaderboard?.allTeams?.[id],
                metricValue: leaderboard?.paceLeaderboard?.[id] || 0
            })).filter(Boolean);

        }

        if (tab === "All" || tab === "Pace") {
            teamsArray = teamsArray.map(team => {
                const metricValueToday = metricTodayMap[team._id] ?? 0;
                const metricValueYesterday = metricYesterdayMap[team._id] ?? 0;
                const metricDifference = metricValueToday - metricValueYesterday;
                let metricPercentageChange = 0;
                if (metricValueYesterday !== 0) {
                    metricPercentageChange = (metricDifference / metricValueYesterday) * 100;
                } else if (metricValueToday !== 0) {
                    metricPercentageChange = 100;
                }

                return {
                    ...team,
                    metricDifference,
                    metricPercentageChange: metricPercentageChange.toFixed(2)
                };
            });
        }

        return teamsArray.sort((a, b) => b.metricValue - a.metricValue);
    }, [leaderboard, challenges, tab, dailyFilter, selectedDate]);

    const [teams, setFilteredTeams] = useState([]);

    const challengeEndDate = new Date() < new Date(challenges.endAt) ? new Date() : new Date(challenges.endAt);
    const endDay = new Date() <= new Date(challenges.endAt) ? 0 : 1;
    const challengePassedDays = tab === "Daily" ? 1 : tab === "Pace" ? (new Date().getDay() + 6) % 7 || 1 : Math.floor((challengeEndDate - new Date(challenges.startAt)) / (1000 * 60 * 60 * 24)) + endDay || 1
    const adjustedLevels = levels.map(level => ({
        ...level,
        treshold: level.treshold.map(value => value === Infinity ? Infinity : value * challenges.maxTeamParticipants * challengePassedDays)
    }))

    const getTreshold = (level) => {
        const foundLevel = adjustedLevels.find(l => l.label === level);
        return foundLevel ? foundLevel.treshold : null;
    };


    useEffect(() => {
        let update = sortedTeams;
        let progressValue = challenges.metric?.minimumValue;
        const metricCP = selectedMetric === 'Currency' ? stepPrice : selectedMetric === 'g Co2 Saved' ? 0.15 : 1;

        if (dailyFilter !== 'Winners') {
            if (selectedCategories.length > 0) {
                update = update.filter(challenger =>
                    selectedCategories.includes(challenger.category)
                );
            }
            if (selectedLevel) {
                const [min, max] = getTreshold(selectedLevel) || [-1, Infinity];
                if (selectedLevel !== 'Legender') {
                    progressValue = max
                }

                update = update.filter(challenger => challenger.metricValue >= min && challenger.metricValue < max);
            }

            if (filter !== '' && update.length > 0) {
                update = update.filter(team =>
                    team.name.toLowerCase().includes(filter.toLowerCase())
                );

            } else {
                update = update;
            }
            if ((selectedMetric === 'Currency' || selectedMetric === 'g Co2 Saved') && dailyFilter !== "Winners") {
                update = update.map(challenger => ({
                    ...challenger,
                    metricValuePrice: Math.round(challenger.metricValue * metricCP),
                    metricDifferencePrice: Math.round(challenger.metricDifference * metricCP)
                }));
            } else if (selectedMetric === 'Steps') {
                update = update.map(challenger => ({
                    ...challenger,
                    metricValuePrice: Math.round(challenger.metricValue / metricCP),
                    metricDifferencePrice: Math.round(challenger.metricDifference / metricCP)
                }));
            }

        }
        setProgress(progressValue)
        setFilteredTeams(update || []);
    }, [stepPrice, dailyFilter, selectedMetric, sortedTeams, selectedLevel, selectedCategories, filter, challenges, activity]);


    return (
        <Box pt={2} maxHeight={openFilter ? '100px' : null}>
            {tab === 'Daily' &&
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingBottom: 2 }}>
                        <Typography onClick={() => setDailyFilter('Leaderboard')} variant="h6" sx={{ color: dailyFilter === 'Leaderboard' ? 'white' : "rgb(127, 134, 153)", textAlign: 'center', marginRight: '20px', cursor: 'pointer' }}>
                            Leaderboard
                        </Typography>
                        <Typography onClick={() => setDailyFilter('Winners')} variant="h6" sx={{ color: dailyFilter === 'Winners' ? 'white' : "rgb(127, 134, 153)", textAlign: 'center', cursor: 'pointer' }}>
                            Winners
                        </Typography>

                    </Box>
                    {dailyFilter === "Leaderboard" &&
                        <Box onClick={() => setOpen(true)} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Typography variant="h6" sx={{ color: 'white', textAlign: 'center', cursor: 'pointer' }}>
                                {formatDateView(selectedDate)}
                            </Typography>
                            <KeyboardArrowDownIcon sx={{ color: '#fff', fontSize: '30px' }} />
                        </Box>}
                </Box>

            }
            <SelectDate min={challenges.startAt} max={challenges.endAt} open={open} setOpen={setOpen} selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
            {loading ? (
                <Loading />

            ) : teams.length === 0 || openFilter ? (
                <Typography variant="h6" sx={{ color: 'white', textAlign: 'center', marginTop: '20px' }}>
                    There are no teams in this challenge
                </Typography>
            ) : (
                teams.map((el, index) => {

                    const element = (selectedMetric === 'Currency' || selectedMetric === 'g Co2 Saved') && dailyFilter !== "Winners" ?
                        { ...el, metricValue: el.metricValuePrice, metricDifference: el.metricDifferencePrice }
                        :
                        el;
                    const challenger = activity === 'Steps' || activity === 'Distance'
                        ? element
                        : {
                            ...element,
                            metricValue: (element.metricValue / 1000).toFixed(2).replace(".", ","),
                            metricDifference: (element.metricDifference / 1000).toFixed(2).replace(".", ","),
                        };
                    return (
                        <Box mb={'5px'} sx={{
                            display: 'flex', flexDirection: 'column', backgroundColor: podium >= (index + 1) ? '#34373F' : 'transparent', borderRadius: podium >= index + 1 && '10px', boxShadow: podium >= index + 1 ? '0px 4px 10px rgba(0, 0, 0, 0.2)' : '0px 3px 7px rgba(0, 0, 0, 0.2)'
                        }}>
                            <Box
                                key={challenger.id || index}
                                display="flex"
                                alignItems="center"
                                mb={'5px'}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    backgroundColor: podium >= (index + 1) ? '#34373F' : 'transparent',
                                    paddingLeft: '10px',
                                    paddingRight: '10px',
                                    paddingTop: '5px',
                                    paddingBottom: '5px',
                                    borderRadius: '10px',

                                }}
                            >
                                <Box sx={BoxLeaderBoard}>
                                    <Typography variant="body1" sx={{ color: 'white', fontWeight: 'bold' }}>
                                        {index + 1}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Avatar
                                        src={challenger.icon}
                                        alt={challenger.name}
                                        sx={{ borderRadius: '0%', width: '45px', height: '45px', display: 'flex', alignSelf: 'end', borderRadius: '6px' }}
                                    />
                                    {avatarImage(challenger.country)}
                                </Box>
                                <Box ml={2} flex="1" display={'flex'} flexDirection={'column'} width={'100%'}>

                                    <Box flex="1" display={'flex'} flexDirection={'row'} width={'100%'}>
                                        <Box flex="1" mt={dailyFilter === 'Winners' && '7px'} display={'flex'} flexDirection={'column'} width={'100%'}>

                                            <Typography variant="body1" sx={{ color: 'white', fontWeight: 'bold' }}>
                                                {challenger.name}
                                            </Typography>
                                            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                                                <PeopleAltIcon sx={{ color: '#fff', marginBottom: '4px' }} fontSize={'small'} />
                                                <Typography variant="body1" sx={{ color: 'white', fontWeight: 'bold', marginBottom: '4px', marginLeft: '5px' }}>
                                                    {challenger.challengers.length}
                                                </Typography>
                                                <FavoriteIcon sx={{ color: '#fff', marginBottom: '4px', marginLeft: '10px' }} fontSize={'small'} />
                                                <Typography variant="body1" sx={{ color: 'white', fontWeight: 'bold', marginBottom: '4px', marginLeft: '5px' }}>
                                                    {challenger?.likes ? Object.keys(challenger.likes).length : 0}
                                                </Typography>
                                            </Box>

                                        </Box>
                                        <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} mt={dailyFilter === 'Leaderboard' && '5px'}>
                                            <Box mb={'1px'} mt={dailyFilter === 'Winners' && '7px'} display={'flex'} alignSelf={'end'}>
                                                <Typography sx={{ color: 'white', alignItems: 'center', display: 'flex', flexDirection: 'row', fontSize: '15px', marginLeft: '-30%' }}>
                                                    {selectedMetric === 'g Co2 Saved' && dailyFilter !== 'Winners' ?
                                                        `${challenger.metricValue?.toLocaleString('de-DE')}   g ` : dailyFilter === "Leaderboard" || tab !== 'Daily'
                                                            ? challenger.metricValue?.toLocaleString('de-DE')
                                                            : `${challenger.metricValue?.toLocaleString('de-DE')}   x `}
                                                    <Icon dailyFilter={dailyFilter} selectedMetric={selectedMetric} activity={activity} tab={tab} />

                                                </Typography>

                                            </Box>
                                            {dailyFilter === 'Leaderboard' &&
                                                <Box mb={'4px'} display={'flex'} alignSelf={'end'}>

                                                    <Typography
                                                        variant="body1"
                                                        sx={{
                                                            color: challenger?.metricDifference >= 0 ? 'rgb(34, 178, 76)' : 'rgb(255, 56, 56)',
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            alignItems: 'center',
                                                            fontWeight: 'bolder',
                                                            fontSize: '13px'
                                                        }}
                                                    >
                                                        {challenger?.metricDifference >= 0 ? (
                                                            <ArrowUpwardIcon sx={{ marginRight: '5px', color: 'rgb(34, 178, 76)', marginBottom: '1px' }} fontSize="small" />
                                                        ) : (
                                                            <ArrowDownwardIcon sx={{ marginRight: '5px', color: 'rgb(255, 56, 56)', marginBottom: '1px' }} fontSize="small" />
                                                        )}
                                                        {activity === 'Cycling' ? challenger.metricDifference : `${Math.abs(challenger?.metricDifference)?.toLocaleString('de-DE')}`}

                                                        <Typography
                                                            variant="body1"
                                                            sx={{
                                                                color: challenger?.metricDifference >= 0 ? 'rgb(34, 178, 76)' : 'rgb(255, 56, 56)',
                                                                alignItems: 'center',
                                                                fontWeight: 'bold',
                                                                marginLeft: '7px',
                                                                marginRight: '7px'
                                                            }}
                                                        >
                                                            |
                                                        </Typography>

                                                        {challenger.metricPercentageChange >= 0
                                                            ? `+${challenger.metricPercentageChange?.toLocaleString('de-DE')}`
                                                            : challenger.metricPercentageChange?.toLocaleString('de-DE')} %
                                                    </Typography>

                                                </Box>}
                                        </Box>

                                    </Box>
                                    {dailyFilter === 'Leaderboard' && <Box sx={{
                                        width: '100%'
                                    }}>
                                        < LinearProgress
                                            variant="determinate"
                                            value={Math.min((el.metricValue / progress) * 100, 100)}
                                            sx={{
                                                height: '3px',
                                                backgroundColor: '#3F434D',
                                                '& .MuiLinearProgress-bar': {
                                                    backgroundColor: calculateColor(index + 1)
                                                }
                                            }}
                                        />
                                    </Box>}
                                </Box>
                                {selectedLevel !== "" && selectedLevel !== "Legender" && dailyFilter === 'Leaderboard' &&
                                    <CircularProgressWithLabel value={el.metricValue} maxValue={progress} />}
                                {dailyFilter === 'Leaderboard' && <Box onClick={() => setOpenTeam(challenger.challengers.length > 0 ? { ...challenger, leaderboardChallengers: leaderboardChallengers.paceLeaderboard, target: challenges.target, challenge: challenges.name, tab: tab, selectedDate: selectedDate, selectedMetric: selectedMetric, stepPrice: stepPrice, activity: activity } : {})} sx={{ cursor: 'pointer', marginTop: '10px' }}>
                                    <ArrowForwardIosIcon sx={{ marginLeft: '20px', color: '#fff' }} fontSize={'small'} />
                                </Box>}
                            </Box>
                        </Box>
                    );
                })
            )}
        </Box >
    );
};

export default LeaderboardTeams;
