import * as React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function CircularProgressWithLabel({ value, maxValue }) {
    const percentage = (value / maxValue) * 100;

    return (
        <Box sx={{ position: 'relative', display: 'flex', marginLeft: '10px', transform: 'scale(0.8)', justifyContent: 'center', alignItems: 'center', marginLeft: '20px', marginTop: '3px' }}>
            <CircularProgress sx={{ color: '#fff', width: '40px !important', height: '40px !important' }} variant="determinate" value={percentage} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    variant="caption"
                    component="div"
                    sx={{ color: '#fff', fontWeight: 'bold' }}
                >
                    {`${Math.round(percentage)}%`}
                </Typography>
            </Box>
        </Box>
    );
}

CircularProgressWithLabel.propTypes = {
    /**
     * The current value of the progress.
     */
    value: PropTypes.number.isRequired,
    /**
     * The maximum value the progress can reach.
     */
    maxValue: PropTypes.number.isRequired,
};
