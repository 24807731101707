import { BorderClear, BorderColor, BorderRight } from '@mui/icons-material'
import { Divider } from '@mui/material'

export const CardComponent = (imgUrl) => {
  return {
    position: 'relative',
    width: '400px',
    height: '240px',
    color: 'white',
    backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),  url(${imgUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: 2,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxShadow: `1px 2px 2px rgba(0, 0, 0, 0.3)`,
    cursor: 'pointer'
  }
}

export const BoxIcon = {
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  alignItems: 'center'
}

export const BoxFlexBetween = {
  display: 'flex',
  justifyContent: 'space-between',
  padding: '8px'
}

export const FlexColumn = {
  display: 'flex',
  flexDirection: 'column'
}

export const BoxStyle = {
  display: 'flex',
  alignItems: 'center',
  padding: '4px 8px',
  borderRadius: '4px',
  gap: 1
}

export const IconSize = {
  width: '20px',
  height: '20px'
}

export const TextStyle = {
  padding: '5px',
  fontWeight: 'bold',
  fontStyle: 'italic'
}

export const BoxStyle2 = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '18px'
}

export const DividerComponent = ({ orientation = 'vertical', flexItem = true, sx = {} }) => {
  return (
    <Divider
      orientation={orientation}
      flexItem={flexItem}
      sx={{ backgroundColor: 'white', height: '35px', marginX: '8px', ...sx }}
    />
  )
}

export const NextArrow = (props) => {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        background: '#000',
        borderRadius: '50%',
        padding: '10px',
        position: 'relative',
        top: '-35px',
        left: '90%',
        transform: 'translateX(60px)',
        zIndex: 1,
        cursor: 'pointer'
      }}
      onClick={onClick}
    />
  )
}

export const PrevArrow = (props) => {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        background: '#000',
        borderRadius: '50%',
        padding: '10px',
        position: 'relative',
        bottom: '-304px',
        left: '85%',
        transform: 'translateX(60px)',
        zIndex: 1,
        cursor: 'pointer'
      }}
      onClick={onClick}
    />
  )
}

export const DialogTitleStyle = {
  position: 'relative',
  padding: 0,
  overflow: 'hidden',
  height: '300px'
}

export const ImageStyle = {
  width: '100%',
  height: '100%',
  objectFit: 'cover'
}

export const OverlayBoxStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  padding: '16px',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-start',
  flexDirection: 'column'
}
export const OverlayRefreshStyle = {
  position: 'absolute',
  top: 10,
  right: 50,
  width: '100%',
  backgroundColor: '#fff',
  height: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  flexDirection: 'column'
}

export const OverlayCloseStyle = {
  position: 'absolute',
  top: 10,
  right: 10,
  width: '100%',
  backgroundColor: '#fff',
  height: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  flexDirection: 'column'
}
export const StatusTypography = (status) => ({
  textAlign: 'center',
  backgroundColor:
    status === "FINALISED" ? "#FF4A4A" :
      status === 'PENDING'
        ? '#F79900'
        : status === 'ACTIVE'
          ? '#0067CF'
          : status === 'ONGOING'
            ? '#01703C'
            : '#007bff',
  color: 'white',
  borderRadius: '4px',
  marginBottom: '8px',
  fontSize: '16px',
  padding: '4px 8px'
})
export const TeamAvatar = {
  marginTop: '10px',
  marginRight: '10px',
  width: '50px',
  height: '50px',
  backgroundColor: '#fff',
  borderRadius: '15%'
}
export const AvatarStyle = {
  width: '50px',
  height: '50px',
  backgroundColor: 'rgba(0,0,0,0.4)',
  margin: 0,
  padding: 0,
  position: 'absolute',
  right: '60px',
  bottom: '60px',
  borderRadius: '15%'
}

export const IconTextWhite = {
  fontSize: '16px',
  color: 'white'
}

export const IconColor = {
  color: '#0067CF',
  marginBottom: '10px'
}

export const IconTextGray = {
  fontSize: '16px',
  color: 'gray'
}

export const ContentBoxStyle = {
  display: 'flex',
  justifyContent: 'space-around',
  flexDirection: 'column',
  overflow: 'auto'
}

export const IconBoxStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '100%'
}
export const Subtitle = {
  color: '#EEEEEE',
  fontSize: 19,
  lineHeight: "20px",
}
export const SecondSubtitle = {
  color: '#7F8699',
  fontSize: 16,
  lineHeight: "20px",
}
export const ContainerInfo = {
  backgroundColor: "#34373F",
  paddingTop: '18px',
  paddingBottom: '18px',
  borderRadius: '8px'
}

export const IconStyle = {
  color: 'white',
  background: '#e6b400',
  padding: 10,
  borderRadius: '25%',
  marginRight: '10px'
}

export const InviteOnlyBoxStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#121212',
  borderRadius: '4px',
  padding: '8px 16px',
  width: '100%',
  height: 60,
  boxSizing: 'border-box'
}

export const HourglassBoxStyle = {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#1212124D',
  borderRadius: '4px',
  borderRadius: '10px',
  padding: '14px 16px',
  boxSizing: 'border-box',
  justifyContent: 'space-between'
}

export const TimeTextStyle = {
  color: '#4fc3f7',
  fontWeight: 'bold',
  textAlign: 'center',
  fontSize: 15,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}

export const ErrorOutStyle = {
  position: 'absolute',
  left: 40,
  color: 'white',
  background: '#e6b400',
  padding: 10,
  borderRadius: '25%',
  marginRight: '10px'
}

export const TextChallenge = {
  color: '#cccc',
  fontWeight: 'bold',
  fontSize: 15,
  textAlign: 'left',
}

export const BoxCFlex = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '100%'
}

export const BoxLeaderBoard = {
  width: '30px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '10px'
}
