import React, { useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Dialog, DialogContent } from '@mui/material';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';

const SelectDate = ({ open, setOpen, selectedDate, setSelectedDate, min, max }) => {
    const [date, setDate] = useState(selectedDate)
    const handleClose = () => {
        setOpen(false);
    };

    const handleDateChange = (newDate) => {
        setDate(newDate);
    };
    const handleAccept = () => {
        setSelectedDate(date)
        handleClose()
    }
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div>
                <Dialog open={open} onClose={handleClose}>
                    <DialogContent>
                        <DemoContainer
                            components={['StaticDatePicker']}
                        >
                            <DemoItem >
                                <StaticDatePicker
                                    minDate={min}
                                    maxDate={new Date() < new Date(max) ? new Date() : new Date(max)}
                                    label="Select a date"
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    renderInput={(params) => <input {...params} />}
                                    onAccept={handleAccept}
                                    onClose={handleClose}
                                />
                            </DemoItem>
                        </DemoContainer>
                    </DialogContent>

                </Dialog>
            </div>
        </LocalizationProvider>
    );
}

export default SelectDate;
