import React, { useState } from "react";
import { Card, CardActionArea, CardContent, Typography } from "@mui/material";
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';

const Level = ({ label, disabled, icon, index, selectedLevel, setSelectedLevel }) => {
    const isSelected = selectedLevel === label;

    const handleClick = () => {
        if (!disabled) {
            setSelectedLevel((prev) => prev === label ? '' : label);
        }
    };

    return (
        <Card
            sx={{
                width: "25%",
                backgroundColor: isSelected ? "#222428" : "transparent",
                textAlign: "center",
                borderRadius: 0,
                borderTopLeftRadius: index === 0 && '8px',
                borderBottomLeftRadius: index === 0 && '8px',
                borderTopRightRadius: index === 3 && '8px',
                borderBottomRightRadius: index === 3 && '8px',
                borderTop: '1px solid #222428',
                borderBottom: '1px solid #222428',
                borderLeft: '1px solid #222428',
                borderRight: index === 3 && '1px solid #222428',
            }}
        >
            <CardActionArea onClick={handleClick} disabled={disabled}>
                <CardContent
                    sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
                >

                    <DirectionsRunIcon sx={{ color: isSelected ? "#057AF6" : "#D5D9DD" }} />
                    <Typography
                        variant="h6"
                        sx={{ color: isSelected ? "#057AF6" : "#D5D9DD" }}
                    >
                        {label}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default Level;
