import { useCallback, useState } from 'react'
import { getLeaderboardsChallengers } from '../api/explore';

const useLeaderboardChallengers = () => {
    const [leaderboard, setLeaderboard] = useState([])
    const [loading, setLoading] = useState(false)
    const [totalCount, setTotalCount] = useState(0);

    const getLeaderboard = useCallback(async (id) => {
        setLoading(true)
        try {
            const res = await getLeaderboardsChallengers(id)

            setLeaderboard(res)
            setTotalCount(res.count)
        } catch (error) {
            console.error('Error fetching challenges', error)
        } finally {
            setLoading(false)
        }
    }, [])

    return { leaderboard, loading, getLeaderboard, totalCount }
}

export default useLeaderboardChallengers
