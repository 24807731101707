import { Avatar, Box, Typography } from "@mui/material"
import { calculateColor } from "./LeaderboardTeams";

const AvatarTeam = ({ challenger, size, index }) => {
    const nameParts = challenger.challengerName.split(" ");
    const challengerName = `${nameParts[0]} ${nameParts[nameParts.length - 1][0]}.`;
    return (
        <Box sx={{ position: 'relative' }}>
            <Avatar
                src={challenger.picture}
                alt={challenger?.challengerName}
                sx={{ borderRadius: '50%', width: size, height: size }} />
            <Box
                sx={{
                    position: 'absolute',
                    marginTop: '-20px',
                    marginLeft: index === 0 || index === 2 ? '26px' : '38px',
                    paddingTop: '2px',
                    paddingBottom: '2px',
                    paddingLeft: '15px',
                    paddingRight: '15px',
                    overflow: 'hidden',
                    borderRadius: '20px',
                    backgroundColor: 'transparent',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: calculateColor(index === 0 ? 2 : index === 1 ? 1 : 3)
                }}
            >
                <Typography variant="p" sx={{ color: 'black', fontWeight: 'bold', fontSize: 20 }}>
                    {index === 0 ? 2 : index === 1 ? 1 : 3}
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingTop: '20px' }}>
                <Typography variant="p" sx={{ color: 'white', fontSize: 20 }}>{challengerName}</Typography>
                <Typography variant="p" sx={{ color: 'white', fontSize: 20, fontWeight: 'bold' }}>{challenger.metricValue}</Typography>
            </Box>
        </Box>
    )
}
export default AvatarTeam;