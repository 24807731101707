import React, { useState, useEffect } from 'react'
import {
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Tabs,
  Tab,
  Stack
} from '@mui/material'
import moment from 'moment/min/moment-with-locales'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import HourglassTopIcon from '@mui/icons-material/HourglassTop'
import LoopIcon from '@mui/icons-material/Loop';
import {
  BoxFlexBetween,
  DialogTitleStyle,
  ImageStyle,
  OverlayBoxStyle,
  StatusTypography,
  AvatarStyle,
  ContentBoxStyle,
  IconBoxStyle,
  HourglassBoxStyle,
  TimeTextStyle,
  TextChallenge,
  IconStyle,
  IconColor,
  ContainerInfo,
  Subtitle,
  SecondSubtitle,
} from './index.styled'
import Leaderboard from './Leaderboard'
import RewardChallenge from './Rewards'
import { backgroundAssets } from './backgroundAssets'
import TabsFilter from './TabsFilter'
import LeaderboardTeams from './LeaderboardTeams'
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import CloseIcon from '@mui/icons-material/Close';
import FilterDialog from './Filter'
import Team from './Team'
import Counter from './Counter'

const DialogChallenge = ({ challenges, open, handleClickClose }) => {
  const [openFilter, setOpenFilter] = useState(false)
  const [selectedDate, setSelectedDate] = useState(new Date() < new Date(challenges.endAt) ? new Date() : new Date(challenges.endAt)
  );
  const [search, setSearch] = useState('')
  const [filter, setFilter] = useState('')
  const [timeRemaining, setTimeRemaining] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  })
  const [selected, selectTab] = useState('All')
  const [tabValue, setTabValue] = useState(0)
  const [selectedLevel, setSelectedLevel] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [openTeam, setOpenTeam] = useState({});
  const [refresh, setRefresh] = useState(new Date())
  const [selectedMetric, setMetric] = useState(challenges.metric?.activity === 'Cycling' ? 'Km' : challenges.metric?.activity === "Distance" ? 'Meters' : "Steps");
  const [dailyFilter, setDailyFilter] = useState('Leaderboard')

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 668);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
    setSelectedLevel('')
    setSelectedCategories([])
    setSelectedDate(new Date() < new Date(challenges.endAt) ? new Date() : new Date(challenges.endAt))
    selectTab("All")
  }
  const getTimeRemaining = (startDate, startHour) => {
    const dateParts = startDate.split('-').map(Number)
    if (dateParts.length !== 3) {
      console.error('Invalid startDate format')
      return { days: 0, hours: 0, minutes: 0, seconds: 0 }
    }
    const [day, month, year] = dateParts

    const timeParts = startHour.split(':').map(Number)
    if (timeParts.length !== 2) {
      console.error('Invalid startHour format')
      return { days: 0, hours: 0, minutes: 0, seconds: 0 }
    }
    const [hour, minute] = timeParts

    const eventDateTime = new Date(year, month - 1, day, hour, minute, 0, 0)

    if (isNaN(eventDateTime)) {
      console.error('Invalid eventDateTime')
      return { days: 0, hours: 0, minutes: 0, seconds: 0 }
    }

    const now = new Date()
    const difference = eventDateTime - now

    if (difference <= 0) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 }
    }

    const totalSeconds = Math.floor(difference / 1000)
    const days = Math.floor(totalSeconds / 86400)
    const hours = Math.floor((totalSeconds % 86400) / 3600)
    const minutes = Math.floor((totalSeconds % 3600) / 60)
    const seconds = totalSeconds % 60

    return { days, hours, minutes, seconds }
  }
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
      const dialogElement = document.querySelector(".MuiDialog-root");
      if (dialogElement) {
        dialogElement.style.overflow = 'hidden';
      }

      return () => {
        document.body.style.overflow = 'auto';
        if (dialogElement) {
          dialogElement.style.overflow = 'auto';
        }
      };
    }
  }, [open]);

  const handleSearchClick = () => {
    setFilter(search);

  }

  useEffect(() => {
    if (open) {
      setTimeRemaining(getTimeRemaining(challenges.startDate, challenges.startHour))

      const interval = setInterval(() => {
        const updatedTime = getTimeRemaining(challenges.startDate, challenges.startHour)
        setTimeRemaining(updatedTime)

        if (
          updatedTime.days === 0 &&
          updatedTime.hours === 0 &&
          updatedTime.minutes === 0 &&
          updatedTime.seconds === 0
        ) {
          clearInterval(interval)
        }
      }, 1000)

      return () => clearInterval(interval)
    }
  }, [open, challenges.startDate, challenges.startHour])
  const tabs = !challenges.isTeamOnly ? ["Challengers", "Rewards"] : ["Teams", "Challengers", "Rewards"]
  const formatDateInterval = (startDate, endDate) => {
    const startDay = moment(startDate, "DD-MM-YYYY").format("DD MMM").toUpperCase();
    const endDay = moment(endDate, "DD-MM-YYYY").format("DD MMM").toUpperCase();

    return [startDay, endDay];
  };

  const handleRefresh = () => {
    setRefresh(new Date())
  }
  const day = formatDateInterval(challenges.startDate, challenges.endDate);

  if (Object.keys(openTeam).length > 0 && !isMobile) {
    return (
      <Team team={openTeam} setOpenTeam={setOpenTeam} />
    );
  }

  return (
    <Dialog open={open} onClose={handleClickClose} fullWidth maxWidth='md'
      minHeight='90%'>
      {!isMobile && <DialogTitle sx={DialogTitleStyle}>
        <Box sx={{ position: 'relative', width: '100%', height: '300px' }}>
          <img
            src={
              challenges.imageUrl.includes('https')
                ? challenges.imageUrl
                : backgroundAssets[challenges.imageUrl]
            }
            alt="Title Image"
            style={ImageStyle}
          />
          <Box onClick={() => handleClickClose()} sx={{
            cursor: 'pointer',
            backgroundColor: '#fff',
            borderRadius: '50%',
            padding: '5px',
            width: '20px',
            height: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            top: 15,
            right: 15,
            zIndex: 10,
          }}>
            <CloseIcon />
          </Box>
          <Box onClick={() => handleRefresh()} sx={{
            cursor: 'pointer',
            backgroundColor: '#fff',
            borderRadius: '50%',
            padding: '5px',
            width: '20px',
            height: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            top: 15,
            right: 60,
            zIndex: 10,
          }}>
            <LoopIcon />
          </Box>
          <Box sx={OverlayBoxStyle}>

            <Box sx={{ marginTop: 'auto', textAlign: 'left' }}>
              {challenges.target &&
                <Box sx={{ marginTop: 'auto' }}>
                  <Counter target={challenges.target} activity={challenges.metric?.activity} />
                </Box>}
              {challenges.statusView && (
                <Typography sx={StatusTypography(challenges.statusView)}>
                  {challenges.statusView === "Coming Soon" ? "Active" : challenges.statusView}
                </Typography>
              )}
              <Box sx={{ ...BoxFlexBetween, flexDirection: 'column', width: '100%' }}>
                <Typography
                  variant="h4"
                  sx={{
                    color: 'white',
                    fontWeight: 'bold',
                    textAlign: 'left',
                    marginBottom: '20px'
                  }}>
                  {challenges.title}
                </Typography>
                {challenges.avatar && challenges.avatar !== '' && (
                  <img src={challenges.avatar} alt="Challenge Avatar" style={AvatarStyle} />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </DialogTitle>}
      <Box display="flex" flexDirection="row" backgroundColor="#222428">
        {isMobile ? (
          <DialogContent>
            <Typography variant="h6" align="center" color="error">
              This feature is not available on mobile devices.
            </Typography>
          </DialogContent>
        ) : (
          <DialogContent
            dividers
            sx={{
              ...ContentBoxStyle
            }}>
            <Box display="flex" justifyContent="space-around" mb={2} sx={ContainerInfo}>
              <Box sx={IconBoxStyle}>
                <DirectionsWalkIcon sx={IconColor} />
                <Box>
                  <Typography sx={Subtitle}>{challenges.steps}</Typography>
                  <Typography sx={SecondSubtitle}> {challenges.metric.activity === 'Cycling' ? `${challenges.metric.activity} (Km)` : challenges.metric.activity}</Typography>
                </Box>

              </Box>
              <Divider orientation='horizontal' sx={{ backgroundColor: '#222428', width: '1px', marginRight: '-10px' }} />

              {challenges.statusView !== "Coming Soon" ? <Box sx={IconBoxStyle}>
                <CalendarTodayIcon sx={IconColor} />
                <Box>
                  <Typography sx={Subtitle}>{day[0]}</Typography>
                  <Typography sx={SecondSubtitle}>{day[1]}</Typography>
                </Box>
              </Box> : <Box sx={IconBoxStyle}>
                <CalendarTodayIcon sx={IconColor} />
                <Box>
                  <Typography sx={Subtitle}>-</Typography>
                  <Typography sx={SecondSubtitle}>-</Typography>
                </Box>
              </Box>
              }
              <Divider orientation='horizontal' sx={{ backgroundColor: '#222428', width: '1px' }} />

              {challenges.statusView !== "Coming Soon" ? <Box sx={IconBoxStyle}>
                <AccessTimeIcon sx={IconColor} />
                <Box>

                  <Typography sx={Subtitle}>{new Date(challenges.startAt).toLocaleTimeString("ro-RO", { hour12: false, hour: "2-digit", minute: "2-digit" })}</Typography>
                  <Typography sx={SecondSubtitle}>{new Date(challenges.endAt).toLocaleTimeString("ro-RO", { hour12: false, hour: "2-digit", minute: "2-digit" })}</Typography>
                </Box>
              </Box> :
                <Box sx={IconBoxStyle}>
                  <AccessTimeIcon sx={IconColor} />
                  <Box>

                    <Typography sx={Subtitle}>-</Typography>
                    <Typography sx={SecondSubtitle}>-</Typography>
                  </Box>
                </Box>}
              <Divider orientation='horizontal' sx={{ backgroundColor: '#222428', width: '1px' }} />

              <Box sx={IconBoxStyle}>
                <PeopleAltIcon sx={IconColor} />
                <Typography sx={Subtitle}>{challenges?.teams ? Object.values(challenges?.teams).length : 0}</Typography>
                <Typography sx={SecondSubtitle}>{challenges.participants}</Typography>
              </Box>
            </Box>
            {challenges.isInviteOnly || timeRemaining.seconds > 0 &&
              (<Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                minHeight={'60px'}>

                {timeRemaining.seconds > 0 && (
                  <Box sx={HourglassBoxStyle}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <HourglassTopIcon style={IconStyle} />
                      <Typography variant="h6" sx={TextChallenge}>
                        CHALLENGE WILL START IN:
                      </Typography>
                    </Box>
                    <Typography variant="h5" sx={TimeTextStyle}>
                      <Stack direction="row" spacing={1.9}>
                        {timeRemaining.days > 0 && (
                          <Stack direction="row" alignItems="center" spacing={0.5}>
                            <Typography variant="body2" sx={{ color: 'rgba(200, 200, 200, 0.7)' }}>Days</Typography>
                            <Typography variant="h4" sx={{ color: 'white', fontWeight: 'bold' }}>{timeRemaining.days}</Typography>
                          </Stack>
                        )}
                        <Stack direction="row" alignItems="center" spacing={0.5}>
                          <Typography variant="body2" sx={{ color: 'rgba(200, 200, 200, 0.7)' }}>Hrs</Typography>
                          <Typography variant="h4" sx={{ color: 'white', fontWeight: 'bold' }}>{timeRemaining.hours}</Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" spacing={0.5}>
                          <Typography variant="body2" sx={{ color: 'rgba(200, 200, 200, 0.7)' }}>Min</Typography>
                          <Typography variant="h4" sx={{ color: 'white', fontWeight: 'bold' }}>{timeRemaining.minutes}</Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" spacing={0.5}>
                          <Typography variant="body2" sx={{ color: 'rgba(200, 200, 200, 0.7)' }}>Sec</Typography>
                          <Typography variant="h4" sx={{ color: 'white', fontWeight: 'bold' }}>{timeRemaining.seconds}</Typography>
                        </Stack>
                      </Stack>
                    </Typography>
                  </Box>
                )}

              </Box>
              )}
            <Box sx={{ width: '100%', boxSizing: 'border-box' }}>
              <Tabs
                value={tabValue}
                textColor="inherit"
                indicatorColor="black"
                sx={{ width: "100%", display: "flex" }}
                onChange={handleTabChange}
              >
                {tabs.map((tab, index) => (
                  <Tab
                    key={index}
                    label={tab}
                    sx={{
                      flex: 1,
                      color: "white",
                      textTransform: "capitalize",
                      fontSize: "1rem",
                      fontWeight: "bold",
                      maxWidth: "none"
                    }}
                  />
                ))}
              </Tabs>
              {(((tabValue === 0 || tabValue === 1) && challenges.isTeamOnly) || tabValue === 0) &&
                  <TabsFilter selectedMetric={selectedMetric} tab={tabValue} handleSearchClick={handleSearchClick} search={search} setSearch={setSearch} isTeamOnly={challenges.isTeamOnly} selectTab={selectTab} selected={selected} setOpenFilter={setOpenFilter} />}
              {tabValue === 0 && challenges.isTeamOnly && <LeaderboardTeams dailyFilter={dailyFilter} setDailyFilter={setDailyFilter} stepPrice={challenges.stepPrice} selectedMetric={selectedMetric} filter={filter} selectedDate={selectedDate} setSelectedDate={setSelectedDate} refresh={refresh} setOpenTeam={setOpenTeam} openFilter={openFilter} selectedCategories={selectedCategories} selectedLevel={selectedLevel} challenges={challenges} tab={selected} />}
              {tabValue === 1 && challenges.isTeamOnly ? <Leaderboard dailyFilter={dailyFilter} setDailyFilter={setDailyFilter} stepPrice={challenges.stepPrice} selectedMetric={selectedMetric} selectedDate={selectedDate} setSelectedDate={setSelectedDate} refresh={refresh} openFilter={openFilter} selectedLevel={selectedLevel} challenges={challenges} tab={selected} /> : tabValue === 0 && !challenges.isTeamOnly && <Leaderboard stepPrice={challenges.stepPrice} selectedMetric={selectedMetric} selectedDate={selectedDate} setSelectedDate={setSelectedDate} selectedLevel={selectedLevel} challenges={challenges} tab={selected} dailyFilter={dailyFilter} setDailyFilter={setDailyFilter} />}
              {tabValue === 2 && challenges.isTeamOnly ? <RewardChallenge challenge={challenges} /> : tabValue === 1 && !challenges.isTeamOnly && <RewardChallenge challenge={challenges} />}
            </Box>
          </DialogContent>)}
      </Box >
      {openFilter && (

        <FilterDialog activity={challenges.metric?.activity} stepPrice={challenges.stepPrice} selectedMetric={selectedMetric} setMetric={setMetric} maxTeamParticipants={challenges.maxTeamParticipants} tabValue={tabValue} team={challenges.isTeamOnly} setOpenFilter={setOpenFilter} categories={challenges.categories} selectedCategories={selectedCategories} setSelectedCategories={setSelectedCategories} selectedLevel={selectedLevel} setSelectedLevel={setSelectedLevel} startAt={new Date(challenges.startAt)} endAt={new Date(challenges.endAt) < new Date() ? new Date(challenges.endAt) : new Date()} />
      )}
    </Dialog >
  )
}

export default DialogChallenge
