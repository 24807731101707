import React, { useEffect, useMemo, useState } from 'react';
import { Avatar, Box, Typography, LinearProgress } from '@mui/material';
import { BoxLeaderBoard } from './index.styled';
import useLeaderboardChallengers from '../../../../../../../hooks/useLeaerboardChallengers';
import { calculateColor, formatDate, formatDateView } from './LeaderboardTeams';
import CountryCodes from '../../../../../../../utils/CountryCode.json';
import ReactCountryFlag from 'react-country-flag';
import { levels } from './Filter';
import SelectDate from './SelectDate';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import useLeaderboard from '../../../../../../../hooks/useLeaderboards';
import Loading from '../../../../../../Payouts/PayoutsTable/Loading';
import CircularProgressWithLabel from './Pregres';
import PDA from './PDA';
import * as USER_REQUESTS from '../../../../../../../api/user';
import Icon from './Icons';

export const avatarImage = (image) => {
  const FlagComponent = CountryCodes[image];

  if (FlagComponent) {
    return (
      <Box
        sx={{
          position: 'absolute',
          width: '20px',
          marginTop: '-10px',
          marginLeft: '10px',
          height: '20px',
          overflow: 'hidden',
          backgroundColor: 'transparent',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ReactCountryFlag
          countryCode={FlagComponent.toUpperCase()}
          svg
          style={{
            width: '100%',
            height: '100%',
            borderRadius: '6px',
          }}
          title={FlagComponent}
        />
      </Box>
    );
  }

  return null;
};
const getLeaderboardDaily = (leaderboard, currentDateKey, challengersByChallengerId) => {

  if (Object.keys(leaderboard).length > 0) {
    const dailyArray = (Object.keys(leaderboard.allChallengers || {}) || [])
      ?.map((id) => {
        const challenger = challengersByChallengerId[id];
        const metricValue = leaderboard.allChallengers[id].dailyMetricValue?.[currentDateKey];
        return challenger && metricValue !== undefined
          ? {
            _id: challenger.id,
            metricValue,
          }
          : {
            _id: challenger.id,
            metricValue: 0
          };
      })
      .filter(Boolean);

    return dailyArray
  } else {
    return []
  }
}

const Leaderboard = ({ challenges, selectedDate, setSelectedDate, tab, refresh, selectedLevel, openFilter, selectedMetric, stepPrice, dailyFilter, setDailyFilter }) => {
  const { leaderboard, getLeaderboard, loading } = useLeaderboardChallengers();
  const { leaderboard: teamsLeaderboard, getLeaderboard: geTeamsLeaderboard, loading: loadingTeams } = useLeaderboard();
  const [activity, setActivity] = useState('Steps')

  const [podium, setPodium] = useState(3)
  const [open, setOpen] = useState(false)
  const [winnersFilter, setWinnersFilter] = useState([])
  const [progress, setProgress] = useState(challenges.metric?.minimumValue)

  useEffect(() => {
    if (challenges && challenges._id) {
      getLeaderboard(challenges._id);
      setActivity(challenges.metric?.activity)
      geTeamsLeaderboard(challenges._id);
    }
  }, [challenges, refresh]);

  const challengersById = useMemo(() => {
    if (!challenges || !challenges.challengers) return {};
    return Object.fromEntries(Object.entries(challenges.challengers));
  }, [challenges]);

  const sortedChallengers = useMemo(() => {
    return Object.entries(challengersById)
      ?.sort(([keyA, a], [keyB, b]) => {
        if (b.metricValue === a.metricValue) {
          return a.challengerName.localeCompare(b.challengerName);
        }
        return b.metricValue - a.metricValue;
      })
      ?.map(([key, challenger]) => ({ id: key, ...challenger }));
  }, [leaderboard]);

  useEffect(() => {
    if (tab === "Pace") {
      setPodium(challenges.podium?.weekly ? challenges.podium.weekly : 1)
    }
    if (tab === "Daily") {
      if (dailyFilter === 'Leaderboard') {
        setPodium(1)
      } else {
        setPodium(challenges.podium?.daily_winners ? challenges.podium.daily_winners : 1)
      }
    }
    if (challenges.podium?.filters && tab) {
      const newPodium = challenges.podium?.filters
      if (tab === "All") {
        if (selectedLevel === '') setPodium(newPodium.legender)
        if (selectedLevel === 'Walker') setPodium(newPodium.walker)
        if (selectedLevel === 'Challenger') setPodium(newPodium.challenger)
        if (selectedLevel === 'Runner') setPodium(newPodium.runner)
        if (selectedLevel === 'Legender') setPodium(newPodium.legender)
      }
    }
  }, [challenges, tab, selectedLevel, dailyFilter])
  const placeWinner = async () => {
    return await USER_REQUESTS.getUsersWeb()
      .then((user) => {
        return user
      })

  }
  useEffect(() => {
    const fetchWinners = async () => {
      const winnersPDA = await placeWinner();
      setWinnersFilter(winnersPDA);
    };

    fetchWinners();
  }, [challenges]);

  const filteredChallengers = useMemo(() => {
    if (!leaderboard || !challenges) return [];

    let challengersArray = [];
    const challengersByChallengerId = Object.fromEntries(
      sortedChallengers.map((challenger) => [challenger.id, challenger])
    );
    const currentDateKey = formatDate(selectedDate) || formatDate(new Date());
    const today = new Date();
    const endAt = new Date(challenges.endAt);

    const yesterday = new Date();
    const beforeYesterday = new Date();

    if (today <= endAt) {
      today.setDate(today.getDate());

      yesterday.setDate(today.getDate() - 1);
      beforeYesterday.setDate(today.getDate() - 2);
    } else {
      today.setDate(endAt.getDate());
      yesterday.setDate(endAt.getDate());
      beforeYesterday.setDate(endAt.getDate() - 1);
    }

    if (leaderboard) {
      if (tab === 'All' && leaderboard.allChallengers) {
        challengersArray = Object.keys(leaderboard.allChallengers)
          .map((id) =>
            challengersByChallengerId[id]
              ? {
                ...challengersByChallengerId[id],
                metricValue: leaderboard.allChallengers[id].metricValue || 0
              }
              : null
          )
          .filter(Boolean);
      } else if (tab === 'Daily' && leaderboard.dailyLeaderboard && leaderboard.allChallengers) {
        if (dailyFilter === "Leaderboard") {
          challengersArray = currentDateKey === formatDate(new Date()) ? Object.keys(leaderboard.dailyLeaderboard || {})
            .map((id) =>
              challengersByChallengerId[id]
                ? {
                  ...challengersByChallengerId[id],
                  metricValue: leaderboard.dailyLeaderboard[id] || 0,
                }
                : null
            )
            .filter(Boolean) : Object.keys(leaderboard.allChallengers || {})
              .map((id) => {
                const challenger = challengersByChallengerId[id];
                const metricValue = leaderboard.allChallengers[id].dailyMetricValue?.[currentDateKey];

                return challenger && metricValue !== undefined
                  ? {
                    ...challenger,
                    metricValue,
                  }
                  : null;
              })
              .filter(Boolean);
          if (currentDateKey === formatDate(new Date())) {
            const today = new Date();
            const endAt = new Date(challenges.endAt);

            const yesterday = new Date();
            const beforeYesterday = new Date();

            if (today <= endAt) {
              today.setDate(today.getDate());

              yesterday.setDate(today.getDate() - 1);
              beforeYesterday.setDate(today.getDate() - 2);
            } else {
              today.setDate(endAt.getDate());
              yesterday.setDate(endAt.getDate());
              beforeYesterday.setDate(endAt.getDate() - 1);

            }
            const metricToday = getLeaderboardDaily(leaderboard, formatDate(yesterday), challengersByChallengerId)
            const metricYesterday = getLeaderboardDaily(leaderboard, formatDate(beforeYesterday), challengersByChallengerId)

            const metricTodayMap = metricToday?.reduce((acc, team) => {
              acc[team._id] = team.metricValue ?? 0;
              return acc;
            }, {});

            const metricYesterdayMap = metricYesterday?.reduce((acc, team) => {
              acc[team._id] = team.metricValue ?? 0;
              return acc;
            }, {});
            challengersArray = challengersArray?.map(challenger => {
              const metricValueToday = metricTodayMap[challenger.id] ?? 0;
              const metricValueYesterday = metricYesterdayMap[challenger.id] ?? 0;
              const metricDifference = metricValueToday - metricValueYesterday;
              let metricPercentageChange = 0;

              if (metricValueYesterday !== 0) {
                metricPercentageChange = (metricDifference / metricValueYesterday) * 100;
              } else if (metricValueToday !== 0) {
                metricPercentageChange = 100;
              }

              const teamIndex = Object.values(teamsLeaderboard?.allTeams || {}) && Object.values(teamsLeaderboard?.allTeams || {})?.findIndex(team =>
                team.challengers.some(c => c.id === challenger.id)
              );

              const team = teamIndex !== -1 && Object.values(teamsLeaderboard?.allTeams || {}) ? Object.values(teamsLeaderboard?.allTeams || {})[teamIndex] : null;

              return {
                ...challenger,
                metricDifference,
                metricPercentageChange: metricPercentageChange.toFixed(2),
                teamName: team ? team.name : null,
                teamPlace: teamIndex !== -1 ? teamIndex + 1 : null
              };
            });
          } else {
            const today = selectedDate
            const yesterday = new Date()
            yesterday.setDate(today.getDate() - 1);

            const metricToday = getLeaderboardDaily(leaderboard, currentDateKey, challengersByChallengerId)
            const metricYesterday = getLeaderboardDaily(leaderboard, formatDate(yesterday), challengersByChallengerId)

            const metricTodayMap = metricToday?.reduce((acc, team) => {
              acc[team._id] = team.metricValue ?? 0;
              return acc;
            }, {});

            const metricYesterdayMap = metricYesterday?.reduce((acc, team) => {
              acc[team._id] = team.metricValue ?? 0;
              return acc;
            }, {});
            challengersArray = challengersArray?.map(challenger => {
              const metricValueToday = metricTodayMap[challenger.id] ?? 0;
              const metricValueYesterday = metricYesterdayMap[challenger.id] ?? 0;
              const metricDifference = metricValueToday - metricValueYesterday;
              let metricPercentageChange = 0;

              if (metricValueYesterday !== 0) {
                metricPercentageChange = (metricDifference / metricValueYesterday) * 100;
              } else if (metricValueToday !== 0) {
                metricPercentageChange = 100;
              }

              const teamIndex = Object.values(teamsLeaderboard?.allTeams || {}) && Object.values(teamsLeaderboard?.allTeams || {})?.findIndex(team =>
                team.challengers.some(c => c.id === challenger.id)
              );

              const team = teamIndex !== -1 && Object.values(teamsLeaderboard?.allTeams || {}) ? Object.values(teamsLeaderboard?.allTeams || {})[teamIndex] : null;

              return {
                ...challenger,
                metricDifference,
                metricPercentageChange: metricPercentageChange.toFixed(2),
                teamName: team ? team.name : null,
                teamPlace: teamIndex !== -1 ? teamIndex + 1 : null
              };
            });
          }
        } else {
          challengersArray = Object.keys(leaderboard.dailyTrophies || {})
            .map((id) =>
              challengersByChallengerId[id]
                ? {
                  ...challengersByChallengerId[id],
                  metricValue: leaderboard.dailyTrophies[id] || 0,
                }
                : null
            )
            .filter(Boolean);
          challengersArray = challengersArray?.map(challenger => {

            const teamIndex = Object.values(teamsLeaderboard?.allTeams || {}) && Object.values(teamsLeaderboard?.allTeams || {})?.findIndex(team =>
              team.challengers.some(c => c.id === challenger.id)
            );

            const team = teamIndex !== -1 && Object.values(teamsLeaderboard?.allTeams || {}) ? Object.values(teamsLeaderboard?.allTeams || {})[teamIndex] : null;

            return {
              ...challenger,
              teamName: team ? team.name : null,
              teamPlace: teamIndex !== -1 ? teamIndex + 1 : null
            };
          });
        }
      } else if (tab === 'Pace' && leaderboard.paceLeaderboard) {
        challengersArray = Object.keys(leaderboard.paceLeaderboard)
          .map((id) =>
            challengersByChallengerId[id]
              ? {
                ...challengersByChallengerId[id],
                metricValue: leaderboard.paceLeaderboard[id] || 0,
              }
              : null
          )
          .filter(Boolean);
      }
    }
    if (tab === "All" || tab === "Pace") {
      const metricToday = getLeaderboardDaily(leaderboard, formatDate(yesterday), challengersByChallengerId)
      const metricYesterday = getLeaderboardDaily(leaderboard, formatDate(beforeYesterday), challengersByChallengerId)

      const metricTodayMap = metricToday?.reduce((acc, team) => {
        acc[team._id] = team.metricValue ?? 0;
        return acc;
      }, {});

      const metricYesterdayMap = metricYesterday?.reduce((acc, team) => {
        acc[team._id] = team.metricValue ?? 0;
        return acc;
      }, {});
      challengersArray = challengersArray?.map(challenger => {
        const metricValueToday = metricTodayMap[challenger.id] ?? 0;
        const metricValueYesterday = metricYesterdayMap[challenger.id] ?? 0;
        const metricDifference = metricValueToday - metricValueYesterday;
        let metricPercentageChange = 0;

        if (metricValueYesterday !== 0) {
          metricPercentageChange = (metricDifference / metricValueYesterday) * 100;
        } else if (metricValueToday !== 0) {
          metricPercentageChange = 100;
        }

        const teamIndex = Object.values(teamsLeaderboard?.allTeams || {}) && Object.values(teamsLeaderboard?.allTeams || {})?.findIndex(team =>
          team.challengers.some(c => c.id === challenger.id)
        );

        const team = teamIndex !== -1 && Object.values(teamsLeaderboard?.allTeams || {}) ? Object.values(teamsLeaderboard?.allTeams || {})[teamIndex] : null;

        return {
          ...challenger,
          metricDifference,
          metricPercentageChange: metricPercentageChange.toFixed(2),
          teamName: team ? team.name : null,
          teamPlace: teamIndex !== -1 ? teamIndex + 1 : null
        };
      });
    }

    return challengersArray.sort((a, b) => b.metricValue - a.metricValue);
  }, [leaderboard, challenges, tab, sortedChallengers, dailyFilter, selectedDate, teamsLeaderboard]);


  const [challengers, setFilteredChallengers] = useState([]);

  const challengeEndDate = new Date() < new Date(challenges.endAt) ? new Date() : new Date(challenges.endAt)
  const endDay = new Date() <= new Date(challenges.endAt) ? 0 : 1

  const challengePassedDays = tab === "Daily" ? 1 : tab === "Pace" ? (new Date().getDay() + 6) % 7 || 1 : Math.floor((challengeEndDate - new Date(challenges.startAt)) / (1000 * 60 * 60 * 24)) + endDay || 1
  const adjustedLevels = levels.map(level => ({
    ...level,
    treshold: level.treshold.map(value => value === Infinity ? Infinity : value * challengePassedDays)
  }))

  const getTreshold = (level) => {
    const foundLevel = adjustedLevels.find(l => l.label === level);
    return foundLevel ? foundLevel.treshold : null;
  };

  useEffect(() => {
    let update = filteredChallengers;
    let progressValue = challenges.metric?.minimumValue;
    const metricCP = selectedMetric === 'Currency' ? stepPrice : selectedMetric === 'g Co2 Saved' ? 0.15 : 1;

    if (dailyFilter !== 'Winners') {
      if (selectedLevel) {
        const [min, max] = getTreshold(selectedLevel) || [-1, Infinity];
        if (selectedLevel !== 'Legender') {
          progressValue = max
        }
        update = filteredChallengers?.filter(challenger => challenger.metricValue >= min && challenger.metricValue < max) || [];

      }
      if ((selectedMetric === 'Currency' || selectedMetric === 'g Co2 Saved') && dailyFilter !== "Winners") {
        update = update.map(challenger => ({
          ...challenger,
          metricValuePrice: Math.round(challenger.metricValue * metricCP),
          metricDifferencePrice: Math.round(challenger.metricDifference * metricCP)
        }));
      } else if (selectedMetric === 'Steps') {
        update = update.map(challenger => ({
          ...challenger,
          metricValuePrice: Math.round(challenger.metricValue / metricCP),
          metricDifferencePrice: Math.round(challenger.metricDifference / metricCP)
        }));
      }


    }
    setProgress(progressValue)
    setFilteredChallengers(update || []);
  }, [stepPrice, filteredChallengers, dailyFilter, selectedMetric, tab, selectedLevel, challenges, activity]);

  return (
    <Box pt={2} maxHeight={openFilter ? '100px' : null}>
      {tab === 'Daily' &&
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingBottom: 2 }}>
            <Typography onClick={() => setDailyFilter('Leaderboard')} variant="h6" sx={{ color: dailyFilter === 'Leaderboard' ? 'white' : "rgb(127, 134, 153)", textAlign: 'center', marginRight: '20px', cursor: 'pointer' }}>
              Leaderboard
            </Typography>
            <Typography onClick={() => setDailyFilter('Winners')} variant="h6" sx={{ color: dailyFilter === 'Winners' ? 'white' : "rgb(127, 134, 153)", textAlign: 'center', cursor: 'pointer' }}>
              Winners
            </Typography>

          </Box>
          {dailyFilter === "Leaderboard" &&
            <Box onClick={() => setOpen(true)} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Typography variant="h6" sx={{ color: 'white', textAlign: 'center', cursor: 'pointer' }}>
                {formatDateView(selectedDate)}
              </Typography>
              <KeyboardArrowDownIcon sx={{ color: '#fff', fontSize: '30px' }} />
            </Box>
            // :
            // <Stack direction="row" spacing={1} >
            //   {['Walker', "Challenger", 'Runner'].map((tab, index) => (
            //     <Chip
            //       key={index}
            //       label={tab}
            //       onClick={() => {
            //         if (tab === winnersFilter) {
            //           setWinnersFilter('')
            //           setDailyFilter('Winners')
            //         } else {
            //           setWinnersFilter(tab)
            //         }
            //       }}
            //       sx={{
            //         backgroundColor: winnersFilter === tab ? '#fff' : "#34373F",
            //         color: winnersFilter === tab ? "#000" : '#fff',
            //         width: '100px',
            //         cursor: 'pointer',
            //         '&:hover': {
            //           backgroundColor: winnersFilter === tab ? '#fff' : "#34373F",
            //           color: winnersFilter === tab ? "#000" : '#fff',
            //         }
            //       }}
            //     />
            //   ))}
            // </Stack>
          }
        </Box>

      }
      <SelectDate min={challenges.startAt} max={challenges.endAt} open={open} setOpen={setOpen} selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
      {(loading || loadingTeams) ? (
        <Loading />

      ) : (challengers.length === 0 || openFilter) ? (
        <Typography variant="h5" sx={{ color: 'white', textAlign: 'center', marginTop: '20px', marginBottom: '20px' }}>
          There are no participants in this challenge
        </Typography>
      ) : (
        challengers.map((el, index) => {

          const element = (selectedMetric === 'Currency' || selectedMetric === 'g Co2 Saved') && dailyFilter !== "Winners" ?
            { ...el, metricValue: el.metricValuePrice, metricDifference: el.metricDifferencePrice }
            :
            el;
          const challenger = activity === 'Steps' || activity === 'Distance'
            ? element
            : {
              ...element,
              metricValue: (element.metricValue / 1000).toFixed(2).replace(".", ","),
              metricDifference: (element.metricDifference / 1000).toFixed(2).replace(".", ","),
            };

          const winner = winnersFilter.find(winner => `${winner.id}` === challenger.id)

          return (
            <Box
              key={challenger.id ? `challenger-${challenger.id}` : `index-${index}`}
              display="flex"
              alignItems="center"
              mb={'5px'}
              sx={{
                backgroundColor:
                  podium >= index + 1 ? '#34373F' : 'transparent',
                padding: '10px',
                borderRadius: podium >= index + 1 && '10px',
                boxShadow: podium >= index + 1 ? '0px 4px 10px rgba(0, 0, 0, 0.2)' : '0px 3px 7px rgba(0, 0, 0, 0.2)'
              }}
            >
              <Box sx={BoxLeaderBoard}>
                <Typography variant="body1" sx={{ color: 'white', fontWeight: 'bold' }}>
                  {index + 1}
                </Typography>
              </Box>
              <Box>
                <Avatar
                  src={challenger.picture}
                  alt={challenger.challengerName}
                  sx={{ borderRadius: '50%', width: '40px', height: '40px' }}
                />
                {avatarImage(challenger.country)}
              </Box>

              <Box ml={2} display={'flex'} flexDirection={'column'} width={'87%'}>
                <Box mt={dailyFilter === 'Winners' && '5px'} mb={'5px'} display={'flex'} flexDirection={'row'} width={'100%'}>
                  <Box flex="1" display={'flex'} flexDirection={'column'} justifyContent={'center'}>
                    <Typography variant="body1" sx={{ color: 'white', fontWeight: 'bold', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      {challenger.challengerName}
                      {winner &&
                        <PDA place={winner.placePDA} />}
                    </Typography>
                    {(challenges.isTeamOnly && challenger.teamPlace && challenger.teamName) ? <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                      <Box sx={{ backgroundColor: '#fff', width: '17px', height: '17px', borderRadius: '4px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginRight: '10px' }}>
                        <Typography variant="string" sx={{ color: 'black', fontWeight: 'bold', fontSize: '12px' }}>
                          {challenger.teamPlace}
                        </Typography>
                      </Box>
                      <Typography variant="body1" sx={{ color: '#7F8699', fontWeight: 'bold' }}>
                        {challenger.teamName}
                      </Typography>

                    </Box> :
                      <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>

                        <Typography variant="body1" sx={{ color: '#7F8699', fontWeight: 'bold' }}>
                          {challenger?.nameOrganization}
                        </Typography>
                      </Box>
                    }

                  </Box>
                  <Box display={'flex'} flexDirection={'column'} marginRight={'10px'} justifyContent={'center'}>
                    <Box mt={dailyFilter === 'Winners' && '5px'} sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                      <Typography variant="body1" sx={{ color: 'white', alignItems: 'center', display: 'flex', flexDirection: 'row', fontSize: '15px' }}>
                        {selectedMetric === 'g Co2 Saved' && dailyFilter !== 'Winners' ?
                          `${challenger.metricValue?.toLocaleString('de-DE')}   g ` :
                          dailyFilter === "Leaderboard" || tab !== 'Daily'
                            ? challenger.metricValue?.toLocaleString('de-DE')
                            : `${challenger.metricValue?.toLocaleString('de-DE')}   x `}

                        <Icon dailyFilter={dailyFilter} selectedMetric={selectedMetric} activity={activity} tab={tab} />
                      </Typography>

                    </Box>
                    {dailyFilter === 'Leaderboard' &&

                      <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginRight: '-15px' }}>
                        <Typography
                          variant="body1"
                          sx={{
                            color: challenger?.metricDifference >= 0 ? 'rgb(34, 178, 76)' : 'rgb(255, 56, 56)',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            fontWeight: 'bolder',
                            fontSize: '13px'
                          }}
                        >
                          {challenger?.metricDifference >= 0 ? (
                            <ArrowUpwardIcon sx={{ marginRight: '5px', color: 'rgb(34, 178, 76)', marginBottom: '1px' }} fontSize="small" />
                          ) : (
                            <ArrowDownwardIcon sx={{ marginRight: '5px', color: 'rgb(255, 56, 56)', marginBottom: '1px' }} fontSize="small" />
                          )}

                          {/* {Math.abs(challenger?.metricDifference)?.toLocaleString('de-DE')} */}
                          {activity === 'Cycling' ? challenger.metricDifference : `${Math.abs(challenger?.metricDifference)?.toLocaleString('de-DE')}`}
                          <Typography
                            variant="body1"
                            sx={{
                              color: challenger?.metricDifference >= 0 ? 'rgb(34, 178, 76)' : 'rgb(255, 56, 56)',
                              alignItems: 'center',
                              fontWeight: 'bold',
                              marginLeft: '7px',
                              marginRight: '7px'
                            }}
                          >
                            |
                          </Typography>

                          {challenger.metricPercentageChange >= 0
                            ? `+${challenger.metricPercentageChange?.toLocaleString('de-DE')}`
                            : challenger.metricPercentageChange?.toLocaleString('de-DE')} %
                        </Typography>


                      </Box>}
                  </Box>
                  {selectedLevel !== "" && selectedLevel !== "Legender" && dailyFilter !== 'Winners' &&
                    <CircularProgressWithLabel value={el.metricValue} maxValue={progress} />}
                </Box>
                {!challenges.isTeamOnly && <Box sx={{ width: '100%' }}>
                  <LinearProgress
                    variant="determinate"
                    value={Math.min((el.metricValue / challenges.metric?.minimumValue) * 100, 100)}
                    sx={{
                      height: '3px',
                      backgroundColor: '#3F434D',
                      '& .MuiLinearProgress-bar': {
                        backgroundColor: calculateColor(index + 1),
                      },
                    }}
                  />
                </Box>
                }
              </Box>
            </Box>
          )
        })
      )}
    </Box >
  );
};

export default Leaderboard;
