import { Box, Chip, Grid, Stack, TextField, Typography } from "@mui/material"
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { TextComponentChallengeStyle } from "../../../../MapSection/subcomponents/CustomMap/subcomponents/Filters/index.style";
const TabsFilter = ({ selectTab, tab, selected, setOpenFilter, search, setSearch, handleSearchClick, isTeamOnly, selectedMetric }) => {
    const tabs = ['All', 'Daily', 'Pace'];

    return (
        <Box spacing={1} sx={{ paddingTop: '20px', paddingBottom: '10px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <Stack direction="row" spacing={1} >
                {tabs.map((tab, index) => (
                    <Chip
                        key={index}
                        label={tab}
                        onClick={() => selectTab(tab)}
                        sx={{
                            backgroundColor: selected === tab ? '#fff' : "#34373F",
                            color: selected === tab ? "#000" : '#fff',
                            width: '80px',
                            cursor: 'pointer',
                            '&:hover': {
                                backgroundColor: selected === tab ? '#fff' : "#34373F",
                                color: selected === tab ? "#000" : '#fff',
                            }
                        }}
                    />
                ))}
            </Stack>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                {isTeamOnly && tab === 0 &&
                    < TextField
                        id="search-input"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        variant="outlined"
                        placeholder="Search teams"
                        InputProps={{
                            endAdornment: (
                                <Chip
                                    onClick={handleSearchClick}
                                    label="Search"
                                    sx={{
                                        backgroundColor: "#34373F",
                                        color: "#FFF",
                                        width: '80px',
                                        height: '32px',
                                        marginRight: '5px',
                                        cursor: 'pointer',
                                        '&:hover': {
                                            backgroundColor: "#34373F",
                                            color: "#FFF",
                                        }
                                    }}

                                />
                            ),
                            style: {
                                backgroundColor: "#34373F",
                            },
                            inputProps: {
                                style: {
                                    paddingLeft: '20px',
                                    color: "#FFF",
                                    width: '200px'
                                },
                            }
                        }}
                        sx={{
                            ...TextComponentChallengeStyle,

                        }}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                handleSearchClick();
                            }
                        }}
                    />}
                <Chip
                    onClick={() => setOpenFilter(true)}
                    label={selectedMetric === 'Currency' ? 'Euro' : selectedMetric}
                    sx={{
                        backgroundColor: "#34373F",
                        color: selectedMetric === 'g Co2 Saved' ? "#22B24C" : "#0075EB",
                        width: selectedMetric === 'g Co2 Saved' ? '120px' : '80px',
                        marginLeft: '10px',
                        cursor: 'pointer',
                        '&:hover': {
                            backgroundColor: "#34373F",
                            color: selectedMetric === 'g Co2 Saved' ? "#22B24C" : "#0075EB",
                        }
                    }}
                    icon={<FilterAltIcon color={selectedMetric === 'g Co2 Saved' ? "#22B24C" : "#0075EB"} fontSize='small' />}

                />
            </Box>
        </Box>
    );
};

export default TabsFilter;
