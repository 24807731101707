import { Box } from '@mui/material'

const RewardChallenge = ({ challenge }) => {
  return (
    <Box
      minHeight={'375px'}
      display="flex"
      flexWrap="wrap" >
      <div
        dangerouslySetInnerHTML={{ __html: challenge.rewardDetails }}
        style={{ color: 'white' }}
      />
    </Box >
  )
}

export default RewardChallenge
