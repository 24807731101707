import { Avatar, Box, Dialog, DialogContent, DialogTitle, Divider, Typography } from "@mui/material"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { BoxLeaderBoard, TeamAvatar } from "./index.styled";
import { avatarImage } from "./Leaderboard";
import { formatDate } from "./LeaderboardTeams";
import AvatarTeam from "./AvatarTeam";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import * as USER_REQUESTS from '../../../../../../../api/user';
import PDA from "./PDA";
import { useEffect, useMemo, useState } from "react";
import Icon from "./Icons";

const Team = ({ team, setOpenTeam }) => {
    const [winnersFilter, setWinnersFilter] = useState([])

    const { icon, target, challenge, name, challengers: challengersTeam, tab, stepPrice, selectedMetric, activity, leaderboardChallengers } = team
    const getDiff = (currentDateKey, challenger) => {
        const metricValue = challenger.dailyMetricValue?.[currentDateKey];
        return challenger && metricValue !== undefined ? metricValue : 0

    }
    const currentDateKey = team?.selectedDate ? formatDate(team?.selectedDate) : new Date()
        .toLocaleDateString("en-GB")
        .replace(/\//g, "-");

    const challengers = [...challengersTeam].map(challenger => {
        let metricDifference;
        const today = new Date();
        const yesterday = new Date();
        const beforeYesterday = new Date();
        yesterday.setDate(today.getDate() - 1);
        beforeYesterday.setDate(today.getDate() - 2);
        const metricValueToday = getDiff(formatDate(yesterday), challenger) ?? 0;
        const metricValueYesterday = getDiff(formatDate(beforeYesterday), challenger) ?? 0;
        let metricPercentageChange = 0;
        let metricValue;

        if (tab === "Daily") {

            if (currentDateKey === formatDate(new Date())) {
                const today = new Date();
                const yesterday = new Date();
                const beforeYesterday = new Date();
                yesterday.setDate(today.getDate() - 1);
                beforeYesterday.setDate(today.getDate() - 2);
                const metricValueTodayDaily = getDiff(formatDate(yesterday), challenger) ?? 0;
                const metricValueYesterdayDaily = getDiff(formatDate(beforeYesterday), challenger) ?? 0;
                metricDifference = metricValueTodayDaily - metricValueYesterdayDaily
                metricValue = challenger.dailyMetricValue?.[currentDateKey] ?? 0;
                if (metricValueYesterday !== 0) {
                    metricPercentageChange = ((metricValueTodayDaily - metricValueYesterdayDaily) / metricValueYesterday) * 100;
                } else if (metricValueToday !== 0) {
                    metricPercentageChange = 100;
                }
            } else {
                const today = team?.selectedDate
                const yesterday = new Date()
                yesterday.setDate(today.getDate() - 1);
                const metricValueTodayDaily = getDiff(currentDateKey, challenger) ?? 0;
                const metricValueYesterdayDaily = getDiff(formatDate(yesterday), challenger) ?? 0;
                metricDifference = metricValueTodayDaily - metricValueYesterdayDaily
                metricValue = challenger.dailyMetricValue?.[currentDateKey] ?? 0;
                if (metricValueYesterday !== 0) {
                    metricPercentageChange = ((metricValueTodayDaily - metricValueYesterdayDaily) / metricValueYesterday) * 100;
                } else if (metricValueToday !== 0) {
                    metricPercentageChange = 100;
                }
            }
        } else if (tab === "Pace") {
            metricDifference = metricValueToday - metricValueYesterday;
            metricValue = leaderboardChallengers[challenger.id];
            if (metricValueYesterday !== 0) {
                metricPercentageChange = ((metricValueToday - metricValueYesterday) / metricValueYesterday) * 100;
            } else if (metricValueToday !== 0) {
                metricPercentageChange = 100;
            }
        } else {
            metricDifference = metricValueToday - metricValueYesterday;

            if (metricValueYesterday !== 0) {
                metricPercentageChange = (metricDifference / metricValueYesterday) * 100;
            } else if (metricValueToday !== 0) {
                metricPercentageChange = 100;
            }
            metricValue = challenger.metricValue;
        }
        return { ...challenger, metricValue, metricDifference, metricPercentageChange: metricPercentageChange.toFixed(2) };
    }).sort((a, b) => b.metricValue - a.metricValue);


    const challengersFilter = useMemo(() => {
        let update = challengers;
        const metricCP = selectedMetric === 'Currency' ? stepPrice : selectedMetric === 'g Co2 Saved' ? 0.15 : 1;

        if (selectedMetric === 'Currency' || selectedMetric === 'g Co2 Saved') {
            update = update.map(challenger => ({
                ...challenger,
                metricValuePrice: Math.round(challenger.metricValue * metricCP),
                metricDifferencePrice: Math.round(challenger.metricDifference * metricCP)
            }));
        } else if (selectedMetric === 'Steps') {
            update = update.map(challenger => ({
                ...challenger,
                metricValuePrice: Math.round(challenger.metricValue / metricCP),
                metricDifferencePrice: Math.round(challenger.metricDifference / metricCP)
            }));
        }

        return update;
    }, [challengers, stepPrice, selectedMetric])


    const placeWinner = async () => {
        return await USER_REQUESTS.getUsersWeb()
            .then((user) => {
                return user
            })

    }
    useEffect(() => {
        const fetchWinners = async () => {
            const winnersPDA = await placeWinner();
            setWinnersFilter(winnersPDA);
        };

        fetchWinners();
    }, [team]);

    return (
        <Dialog open={open} fullWidth maxWidth="md">
            <DialogTitle sx={{ backgroundColor: "#222428" }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', backgroundColor: "#222428" }}>
                    <Box onClick={() => setOpenTeam({})} sx={{ backgroundColor: 'white', borderRadius: '50%', padding: '5px', paddingBottom: '-5px', width: '20px', height: '20px', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <ArrowBackIcon />
                    </Box>
                </Box>
            </DialogTitle>

            <DialogContent
                sx={{ display: "flex", flexDirection: 'column', justifyContent: "center", minHeight: '300px', backgroundColor: "#222428" }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', paddingTop: '50px' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', borderRadius: '6px', border: '1px solid #34373F', width: '100%' }}>
                        <Typography variant="h6" color="#7F8699" sx={{ padding: '10px', borderBottom: '1px solid #34373F' }}>
                            {`${challenge} - ${tab} leaderboard`}
                        </Typography>
                        <Divider orientation='horizontal' sx={{ backgroundColor: '#34373F', width: '100%' }} />
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', padding: '10px' }}>
                            <Typography variant="h4" color="white" width={'20%'}>
                                {name}
                            </Typography>
                            {icon && icon !== '' && (
                                <img src={icon} alt="Challenge Avatar" style={TeamAvatar} />
                            )}
                        </Box>
                        <Typography variant="h6" color="#7F8699" sx={{ paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', borderBottom: '1px solid #34373F' }}>
                            {challengers[0]?.challengerName
                                ? `Team Lead is ${challengers[0]?.challengerName}`
                                : `There is no Team Lead at the moment`}
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end', height: '200px', marginBottom: '20px', width: '70%' }}>
                            {[challengers[1] || null, challengers[0], challengers[2] || null].map((challenger, index) => (
                                challenger ? (
                                    <AvatarTeam key={index} index={index} challenger={challenger} size={index === 0 || index === 2 ? "90px" : "120px"} />
                                ) : (
                                    <Box key={index} sx={{ width: index === 0 || index === 2 ? "90px" : "120px", height: index === 0 || index === 2 ? "90px" : "120px" }} />
                                )
                            ))}
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>

                        {challengers.length === 0 ? (
                            <Typography variant="h4" sx={{ color: 'white', textAlign: 'center', marginTop: '20px' }}>
                                There are no participants in this challenge
                            </Typography>
                        ) : (
                            challengersFilter.map((el, index) => {
                                const element = (selectedMetric === 'Currency' || selectedMetric === 'g Co2 Saved') ?
                                    { ...el, metricValue: el.metricValuePrice, metricDifference: el.metricDifferencePrice }
                                    :
                                    el;
                                const challenger = activity === 'Steps'
                                    ? element
                                    : {
                                        ...element,
                                        metricValue: (element.metricValue / 1000).toFixed(2).replace(".", ","),
                                        metricDifference: (element.metricDifference / 1000).toFixed(2).replace(".", ","),
                                    };
                                const winner = winnersFilter.find(winner => `${winner.id}` === challenger.id)

                                return (
                                    <Box
                                        key={challenger.id ? `challenger-${challenger.id}` : `index-${index}`}
                                        display="flex"
                                        alignItems="center"
                                        mb={'5px'}
                                        sx={{
                                            backgroundColor:
                                                '#34373F',
                                            padding: '10px',
                                            borderRadius: '10px',
                                        }}
                                    >
                                        <Box sx={BoxLeaderBoard}>
                                            <Typography variant="body1" sx={{ color: 'white', fontWeight: 'bold' }}>
                                                {index + 1}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Avatar
                                                src={challenger.picture}
                                                alt={challenger?.challengerName}
                                                sx={{ borderRadius: '50%', width: '40px', height: '40px' }}
                                            />
                                            {avatarImage(challenger.country)}
                                        </Box>

                                        <Box ml={2} display={'flex'} flexDirection={'column'} width={'87%'}>
                                            <Box mb={'5px'} display={'flex'} flexDirection={'row'} width={'100%'}>
                                                <Box flex="1" display={'flex'} flexDirection={'column'} justifyContent={'center'}>
                                                    <Typography variant="body1" sx={{ color: 'white', fontWeight: 'bold', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                        {challenger?.challengerName}
                                                        {winner &&
                                                            <PDA place={winner.placePDA} />}
                                                    </Typography>
                                                </Box>
                                                <Box display={'flex'} flexDirection={'column'} marginRight={'10px'} justifyContent={'center'}>

                                                    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>

                                                        <Typography variant="body1" sx={{ color: 'white', alignItems: 'center', flexDirection: 'row', display: 'flex' }}>
                                                            {selectedMetric === 'g Co2 Saved' ?
                                                                `${challenger.metricValue}   g ` :
                                                                challenger.metricValue?.toLocaleString('de-DE')}
                                                            <Icon dailyFilter={"Leaderboard"} selectedMetric={selectedMetric} activity={activity} tab={tab} />

                                                        </Typography>

                                                    </Box>
                                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginRight: '-15px' }}>
                                                        <Typography
                                                            variant="body1"
                                                            sx={{
                                                                color: `${challenger?.metricDifference}`?.split("")[0] !== '-' ? 'rgb(34, 178, 76)' : 'rgb(255, 56, 56)',
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                alignItems: 'center',
                                                                fontWeight: 'bolder',
                                                                fontSize: '13px'
                                                            }}
                                                        >
                                                            {`${challenger?.metricDifference}`?.split("")[0] !== '-' ? (
                                                                <ArrowUpwardIcon sx={{ marginRight: '5px', color: 'rgb(34, 178, 76)', marginBottom: '1px' }} fontSize="small" />
                                                            ) : (
                                                                <ArrowDownwardIcon sx={{ marginRight: '5px', color: 'rgb(255, 56, 56)', marginBottom: '1px' }} fontSize="small" />
                                                            )}

                                                            {activity === 'Cycling' ? challenger.metricDifference : `${Math.abs(challenger?.metricDifference)?.toLocaleString('de-DE')}`}

                                                            <Typography
                                                                variant="body1"
                                                                sx={{
                                                                    color: `${challenger?.metricDifference}`?.split("")[0] !== '-' ? 'rgb(34, 178, 76)' : 'rgb(255, 56, 56)',
                                                                    alignItems: 'center',
                                                                    fontWeight: 'bold',
                                                                    marginLeft: '7px',
                                                                    marginRight: '7px'
                                                                }}
                                                            >
                                                                |
                                                            </Typography>

                                                            {`${challenger.metricPercentageChange}`.split('')[0] !== '-'
                                                                ? `+${challenger.metricPercentageChange.toLocaleString('de-DE')}`
                                                                : challenger.metricPercentageChange.toLocaleString('de-DE')} %
                                                        </Typography>

                                                    </Box>
                                                </Box>
                                            </Box>

                                        </Box>
                                    </Box>
                                )
                            })
                        )}
                    </Box>
                </Box>

            </DialogContent>
        </Dialog >
    )
}
export default Team