import React, { useEffect, useState, useMemo } from 'react'
import { Dialog, IconButton, Button, Typography, Grid, Box, Divider } from '@mui/material'
import { Close, Warning } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import * as COMPANY_REQUESTS from '../../../../api/company'
import * as PAYMENT_REQUESTS from '../../../../api/payments'
import { numberFomatter } from '../../../../utils/helpers/numberFomatter'
import { ORANGE_COLOR, PRIMARY_COLOR } from '../../../../utils/constants'
import { useAuthentication } from '../../../../context/auth-context'
import { createInvoice } from '../../../../utils/helpers/cashRegister'

const GenerateInvoice = ({
    onCancel,
    onConfirm,
    data
}) => {
    const { t } = useTranslation()
    const { user } = useAuthentication()

    const [userSubscription, setUserSubscription] = useState()
    const [company, setCompany] = useState()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (!user) return
        COMPANY_REQUESTS.getBusiness(user.companyId)
            .then(response => {
                setCompany(response)
            })
    }, [user])

    useEffect(() => {
        setUserSubscription(data)
    }, [data])

    const tvaValue = useMemo(() => {
        if (!company || !company.vat) return 0

        return Number(Number(Number(data.price) - Number(data.price) / (1 + 19 / 100)).toFixed(2))
    }, [data, company])

    const nonTvaValue = useMemo(() => {
        if (!company || !company.vat) return data.price

        return Number(Number(Number(data.price) - Number(tvaValue)).toFixed(2))
    }, [data, company, tvaValue])

    if (!userSubscription) return null

    const fullName = `${userSubscription.User.firstName} ${userSubscription.User.lastName}`

    const computeFileText = () => {
        setLoading(true)
        const price = Number(userSubscription.price) * 100
        const hasDiscount = userSubscription.Voucher || userSubscription.RecurringVoucher
        let discountValue = 0
        let fixedDiscount = false
        let discountText = ''

        if (hasDiscount) {
            fixedDiscount = hasDiscount.hasFixedDiscount
            discountValue = hasDiscount.discount
            discountText = fixedDiscount ? `DV^${Number(discountValue) * 100}\n` : `DP^${Number(discountValue) * 100}\n`
        }
        let data = [
            `CF^RO ${company.cui}`,
            `S^${userSubscription.Subscription.name}^${price}^1000^buc^1^1`,
            `${discountText}TL^Cumparere ${userSubscription.Subscription.name}`,
            `P^1^${price}`
        ]

        return createInvoice(data)
            .then(() => PAYMENT_REQUESTS.generateInvoice(userSubscription.id))
            .catch(() => {
                console.error('Something went wrong!')
            })
            .finally(() => {
                onConfirm()
                setLoading(false)
            })
    }

    return (
        <Dialog open maxWidth="sm" fullWidth>
            <Grid container p={4}>
                <Grid item xs={12}>
                    <Grid container display="flex" flexDirection="row">
                        <Grid item
                            xs={10}
                            md={10}
                            lg={10}
                            pr={{ xs: 0, md: 0, lg: 2 }}
                            pl={{ xs: 0, md: 0, lg: 2 }}>
                            <Typography variant="h4" fontWeight={500} color={'black'}>
                                {userSubscription.isInvoiceGenerated ? t('clients.regenerateInvoice') : t('clients.generateInvoice')}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} display="flex" flexDirection="row" justifyContent="flex-end">
                            <IconButton onClick={onCancel}>
                                <Close />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
                {userSubscription.isInvoiceGenerated ? <>
                <Grid item xs={12} pt={4} pb={4} pr={{ xs: 0, md: 0, lg: 2 }}
                    pl={{ xs: 0, md: 0, lg: 2 }} >
                    <Typography variant="h6" fontWeight={500} color={ORANGE_COLOR} display="flex" flexDirection="row" alignItems="center">
                        <Warning sx={{ color: ORANGE_COLOR, pr: 2 }} />
                        {t('clients.warningInvoice')}</Typography>
                </Grid>
                <Grid item xs={12} pr={{ xs: 0, md: 0, lg: 2 }}
                    pl={{ xs: 0, md: 0, lg: 2 }}>
                    <Divider />
                </Grid>
                </>: null}
                <Grid
                    item
                    pt={1}
                    pb={4}
                    xs={12}
                    display="flex"
                    flexDirection="column"
                    pr={{ xs: 0, md: 0, lg: 2 }}
                    pl={{ xs: 0, md: 0, lg: 2 }}
                >
                    <Typography gutterBottom variant="p" pt={2} pb={2}>{t('clients.generateInvoiceFor')} <b>{userSubscription.Subscription.name}</b>, {t('clients.forTheUser')} <b>{fullName}</b>.</Typography>
                    {userSubscription.Voucher ? <Divider /> : null}
                    {userSubscription.Voucher ? <Box sx={{ width: '100%', paddingTop: 2, paddingBottom: 2 }}>
                        <Typography variant="body1">{userSubscription.Voucher.name}</Typography>
                        <Typography variant="body1" color={PRIMARY_COLOR} fontWeight="bold">{userSubscription.Voucher.discount} {userSubscription.Voucher.hasFixedDiscount ? 'RON' : '%'}</Typography>
                    </Box> : null}
                    {userSubscription.RecurringVoucher ? <Divider /> : null}
                    {userSubscription.RecurringVoucher ? <Box sx={{ width: '100%', paddingTop: 2, paddingBottom: 2 }}>
                        <Typography variant="body1">{userSubscription.RecurringVoucher.name}</Typography>
                        <Typography variant="body1" color={PRIMARY_COLOR} fontWeight="bold">{numberFomatter(userSubscription.Voucher.discount)} {userSubscription.Voucher.hasFixedDiscount ? 'RON' : '%'}</Typography>
                    </Box> : null}
                    <Divider />
                    <Box sx={{ width: '100%', paddingTop: 2 }}>
                        <Typography variant="body1">{t('clients.totalValueInvoice')}</Typography>
                        <Typography variant="body1" fontWeight="bold">{numberFomatter(userSubscription.price)} RON</Typography>
                    </Box>
                    {company && company.vat ?
                        <>
                            <Box sx={{ width: '100%', paddingTop: 2 }}>
                                <Typography variant="body1">{t('clients.totalVATValueInvoice')}</Typography>
                                <Typography variant="body1" fontWeight="bold">{numberFomatter(tvaValue)} RON</Typography>
                            </Box>
                            <Box sx={{ width: '100%', paddingTop: 2 }}>
                                <Typography variant="body1">{t('clients.netValueInvoice')}</Typography>
                                <Typography variant="body1" fontWeight="bold">{numberFomatter(nonTvaValue)} RON</Typography>
                            </Box>
                        </> : null}
                </Grid>
                <Grid item xs={12} pt={3}>
                    <Grid container display="flex" flexDirection="row" spacing={3}>
                        <Grid item xs={12} md={12} lg={6}>
                            <Button style={{ minHeight: 45 }} onClick={onCancel} fullWidth variant="outlined">
                                {t('clients.cancel')}
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={12} lg={6}>
                            <Button
                                style={{ minHeight: 45 }}
                                onClick={computeFileText}
                                fullWidth
                                disabled={loading}
                                color="primary"
                                variant="contained"
                            >
                                {t('clients.generateInvoice')}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    )
}

export default GenerateInvoice
