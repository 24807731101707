import React, { useEffect, useState } from 'react'
import { Dialog, IconButton, Button, Typography, Grid, Avatar } from '@mui/material'
import { Close } from '@mui/icons-material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'

import * as COMPANY_REQUESTS from '../../../../api/company'
import * as USER_SUBSCRIPTION_REQUESTS from '../../../../api/userSubscriptions'
import { useAuthentication } from '../../../../context/auth-context'
import { COMPANIES_TRAINERS_REQUEST_STATUS } from '../../../../utils/constants'

const PauseSubscriptionDialog = ({
    onCancel,
    userSubscription,
    onConfirm
}) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const { user } = useAuthentication()
    const matchesTablet = useMediaQuery(theme.breakpoints.down('md'))
    const [loading, setLoading] = useState(false)
    const [selectedTrainer, setSelectedTrainer] = useState()
    const [trainers, setTrainers] = useState([])

    useEffect(() => {
        if (userSubscription && userSubscription.GeneratedTrainer) {
            setSelectedTrainer(userSubscription.GeneratedTrainer.id)
        }
    }, [userSubscription])

    const onSave = () => {
        setLoading(true)

        const data = {
            generatedTrainerId: selectedTrainer || null
        }

        return USER_SUBSCRIPTION_REQUESTS.update(userSubscription.id, data)
            .then(onConfirm)
            .finally(() => {
                setLoading(false)
                onCancel()
            })
    }

    useEffect(() => {
        if (!user) return
        COMPANY_REQUESTS.getAllMembers(user.companyId)
            .then(response => {
                setTrainers(response.filter(el => el.status === COMPANIES_TRAINERS_REQUEST_STATUS.APPROVED))
            })

    }, [user])

    if (!userSubscription) return null

    return (
        <Dialog open maxWidth="sm" fullScreen={matchesTablet} fullWidth PaperProps={{ ...(matchesTablet ? { sx: { borderRadius: 0 } } : {}) }}>
            <Grid container p={4}>
                <Grid item xs={12}>
                    <Grid container display="flex" flexDirection="row">
                        <Grid item
                            xs={10}
                            md={10}
                            lg={10}
                            pr={{ xs: 0, md: 0, lg: 2 }}
                            pl={{ xs: 0, md: 0, lg: 2 }}>
                            <Typography variant="h4" fontWeight={500} color={'black'}>
                                {t('clients.changeTrainer')}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} display="flex" flexDirection="row" justifyContent="flex-end">
                            <IconButton onClick={onCancel}>
                                <Close />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} pt={4} pb={2} pr={{ xs: 0, md: 0, lg: 2 }}
                    pl={{ xs: 0, md: 0, lg: 2 }}>
                    <Grid container spacing={2}>
                        {trainers.map((el, index) => {
                            const isSelected = el.id === selectedTrainer

                            return <Grid item xs={12} id={index}>
                                <Grid container display="flex" flexDirection="row">
                                    <Grid item xs={8} display="flex" flexDirection="row" alignItems="center">
                                        <Avatar src={el.avatarPhotoSrc}>{el.firstName[0]}{el.lastName[0]}</Avatar>
                                        <Typography variant="p" fontWeight={500} pl={1}>
                                            {el.firstName} {el.lastName}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        {isSelected ? <Button onClick={() => setSelectedTrainer()} fullWidth variant="contained">Remove</Button> : <Button onClick={() => setSelectedTrainer(el.id)} fullWidth variant="outlined">Select</Button>}
                                    </Grid>
                                </Grid>
                            </Grid>
                        })}
                    </Grid>
                </Grid>
                <Grid item xs={12} pt={3} pr={{ xs: 0, md: 0, lg: 2 }}
                    pl={{ xs: 0, md: 0, lg: 2 }}>
                    <Grid container display="flex" flexDirection="row" spacing={3}>
                        <Grid item xs={12} md={12} lg={6}>
                            <Button onClick={onCancel} fullWidth variant="outlined">
                                {t('clients.cancel')}
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={12} lg={6}>
                            <Button
                                style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                                onClick={onSave}
                                fullWidth
                                disabled={loading}
                                variant="contained"
                            >
                                {t('button.save')}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    )
}

export default PauseSubscriptionDialog
