import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import ChallengeCard, { calculateStatus } from './Components/ChalangesCard'
import useChallenges from '../../../../../../hooks/useChallanges'
import CustomSlider from '../../../../../common/CustomSlider'
import AllCards from './Components/AllCards'
import DialogChallenge from './Components/Dialog'
import BoxElements from '../../../../../../utils/boxElementsV2'
import { useExplore } from '../../../../../../context/explore-context'
import BoxContainer from '../../../../../../utils/boxContainer'

const ChallengeSlider = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const itemsPerRow = isMobile ? 1 : 4
  const [showAll, setShowAll] = useState(false)
  const { challenges, getChallenges, totalCount } = useChallenges()
  const [selectedChallenge, setSelectedChallenge] = useState(null)
  const [openDialog, setOpenDialog] = useState(false)
  const { searchQuery } = useExplore()

  const fetchChallenges = useCallback(
    async (targetIndex) => {
      const newLimit = (targetIndex + 1) * itemsPerRow + itemsPerRow
      await getChallenges(0, 100000000, searchQuery)
    },
    [getChallenges, itemsPerRow, searchQuery]
  )

  useEffect(() => {
    if (showAll) return
    fetchChallenges(100000000)
  }, [fetchChallenges, showAll])

  const handleOpenChallenge = (challenge) => {
    const getShortId = (id, length = 4) => {
      if (typeof id !== 'string') return ''
      return id.slice(0, length)
    }
    setSelectedChallenge(challenge)
    setOpenDialog(true)
    const shortId = getShortId(challenge.id._id)
    const url = new URL(window.location)
    url.searchParams.set('challenge', shortId)
    window.history.pushState({}, '', url)
  }

  const handleCloseChallenge = () => {
    setOpenDialog(false)
    setSelectedChallenge(null)
    const url = new URL(window.location)
    url.searchParams.delete('challenge')
    window.history.pushState({}, '', url)
  }

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const challengeId = params.get('challenge')
    if (challengeId) {
      const challenge = challenges.find((c) => c.id._id === challengeId)
      if (challenge) {
        setSelectedChallenge(challenge)
        setOpenDialog(true)
      }
    }
  }, [challenges])

  useEffect(() => {
    const handlePopState = () => {
      const params = new URLSearchParams(window.location.search)
      const challengeId = params.get('challenge')
      if (challengeId) {
        const challenge = challenges.find((c) => c.id._id === challengeId)
        if (challenge) {
          setSelectedChallenge(challenge)
          setOpenDialog(true)
        }
      } else {
        setOpenDialog(false)
        setSelectedChallenge(null)
      }
    }

    window.addEventListener('popstate', handlePopState)

    return () => {
      window.removeEventListener('popstate', handlePopState)
    }
  }, [challenges])

  const renderChallengesByStatus = (status) =>
    challenges
      .filter(challenge => calculateStatus(challenge.startAt, challenge.endAt) === status)
      .sort((a, b) => status === "FINALISED" ? new Date(b.startAt) - new Date(a.startAt) :
         new Date(a.startAt) - new Date(b.startAt))
      .map((challenge, index) => (
        <Box
          key={challenge.id._id || index}
          marginRight={!isMobile && 1}
          padding={!isMobile && '0 0 10px 10px'}
        >
          <ChallengeCard
            challenges={challenge}
            onOpen={() =>
              handleOpenChallenge({ ...challenge, statusView: status })
            }
          />
        </Box>
      ));

  return (
    <BoxContainer>
      {showAll && challenges.length > 0 ? (
        <AllCards isShowAll={showAll} setIsShowAll={setShowAll} handleOpenChallenge={handleOpenChallenge} />
      ) : (
        <BoxElements>

          <Box display="flex" justifyContent="space-between" alignItems="center" sx={{
            mb: 2, width: '100%'
          }}>
            <Typography variant="h5" fontWeight="bold" sx={{
              ml: 1,
              fontSize: isMobile ? '1rem' : '1.3rem',

            }}>
              Challenges
            </Typography>
            {challenges.length > 0 && (
              <Typography
                variant="h5"
                color="primary"
                style={{
                  cursor: 'pointer', marginRight: '10px',
                  fontSize: isMobile ? '1rem' : '1.3rem',
                }}
                onClick={() => setShowAll((prev) => !prev)}>
                {showAll ? 'Show Less' : 'Show All'}
              </Typography>)}
          </Box>
          {challenges.length > 0 ? (<>
            <CustomSlider totalCount={totalCount} fetch={fetchChallenges} >
              {renderChallengesByStatus('ACTIVE')}
            </CustomSlider>
            <CustomSlider totalCount={totalCount} fetch={fetchChallenges} >
              {renderChallengesByStatus('PENDING')}
            </CustomSlider>
            <CustomSlider totalCount={totalCount} fetch={fetchChallenges} >
              {renderChallengesByStatus('FINALISED')}
            </CustomSlider>
          </>) :
            (
              <Typography sx={{ marginTop: isMobile ? '-40px' : '-50px', fontSize: isMobile ? '1rem' : '1.3rem', }} variant="h6" color="text.secondary">

                No challenges available</Typography>
            )}
        </BoxElements>

      )}
      {selectedChallenge && (
        <DialogChallenge
          challenges={selectedChallenge}
          open={openDialog}
          handleClickClose={handleCloseChallenge}
        />
      )}
    </BoxContainer>

  )
}

export default ChallengeSlider
