import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import Co2Icon from '@mui/icons-material/Co2';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import STEPS from '../../../../../../../assets/steps.png';
import LEAF from '../../../../../../../assets/leaf.png';
import { Box, Typography } from '@mui/material';


const Icon = ({ dailyFilter, selectedMetric, activity, tab  }) => {
    return (
        <Box>
            {selectedMetric === 'Currency' && dailyFilter !== 'Winners' ? (
                <EuroSymbolIcon sx={{
                    marginLeft: '5px',
                    color: '#fff',
                    marginBottom: '-2px',
                    marginRight: '-10px',
                    fontSize: '15px',
                }} />
            ) : selectedMetric === 'g Co2 Saved' && dailyFilter !== 'Winners' ? (
                <Box display={'flex'} flexDirection={'row'} alignItems={'center'} sx={{
                    marginBottom: '-4%',
                    marginRight: '-10px',
                    width: 'auto'
                }}>
                    <Co2Icon sx={{
                        marginLeft: '1px', color: '#fff',
                        marginRight: '3px',

                    }} fontSize="large" />
                        <img
                            style={{ position: 'relative', left: '10%', marginBottom: '-4%', objectFit: 'cover' }}
                            height="20px"
                            src={LEAF}
                            width="20px"
                        />
                </Box>

            ) : (dailyFilter === "Leaderboard" || tab !== 'Daily') && activity === 'Steps' ? (
                <img
                    style={{ position: 'relative', left: '20%', marginBottom: '-4%', objectFit: 'cover' }}
                    height="15px"
                    src={STEPS}
                    width="15px"
                />
            ) : activity === 'Cycling' && (dailyFilter === "Leaderboard" || tab !== 'Daily') ?
                <DirectionsBikeIcon sx={{
                    marginLeft: '10px', color: '#fff', marginBottom: '2px',
                    marginRight: '-10px',

                }} fontSize="small" /> :
                activity === 'Distance' && (dailyFilter === "Leaderboard" || tab !== 'Daily') ? <Typography variant="body1" sx={{
                    marginLeft: '5px',
                    marginRight: '-10px',
                }}>
                    m
                </Typography> :

                    (
                        tab === 'Daily' &&
                        dailyFilter === "Winners" && (
                            <EmojiEventsIcon sx={{ marginLeft: '2px', color: '#fff', marginBottom: '-3px' }} fontSize="small" />
                        )
                    )}
        </Box>
    )
}
export default Icon;