import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { Box, Card, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next'
import { useState } from 'react';
import * as DASHBOARD_REQUESTS from '../../../api/dashboard'
import { ROLE_BUSINESS_ADMINISTRATOR, GRAY_TEXT_COLOR } from '../../../utils/constants'
import { useAuthentication } from '../../../context/auth-context';
import { useEffect } from 'react';

const Costs = () => {
    const { t } = useTranslation()
    const { user } = useAuthentication()
    const [data, setData] = useState([{ month: 'Jan', data: 0, costs: 0, dataCost: 0 }])
    const parentRef = React.useRef(null);
    const [size, setSize] = useState({ width: 0, height: 0 });
    useEffect(() => {
        const handleResize = () => {
            if (parentRef.current) {
                const isSmallScreen = window.innerWidth < 750 || window.innerHeight < 900;
                const maxHeight = isSmallScreen ? 200 : 400;
                const minWidth = isSmallScreen ? 200 : 700;

                setSize({
                    width: Math.max(parentRef.current.offsetWidth, minWidth),
                    height: Math.max(parentRef.current.offsetHeight, maxHeight)
                });
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const fetchData = async () => {
        try {
            const response = await DASHBOARD_REQUESTS.getReportsCompanyOverviewYear()
            setData(response)

        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (!user || !user.role === ROLE_BUSINESS_ADMINISTRATOR) return
        fetchData()
    }, [user])

    return (
        <>

            <Box display="flex" flexDirection="column">
                <Box display="flex" flexDirection="column">
                    <Typography variant="p" fontWeight={'bold'} fontSize={20} pl={1}>
                        {t('dashboard.overview.costs')} 1%
                    </Typography>
                    <Typography variant="p" pb={1} pl={1} color={GRAY_TEXT_COLOR}>{t('reports.currentYear')}</Typography>

                </Box>
                <Grid container component={Card} sx={{
                    boxShadow: '0 2px 12px 0 rgba(41, 40, 56, 0.08)',
                    border: '1px solid #F0F0F0',
                    borderRadius: 2
                }} p={2} mt={1}>
                    <Grid item xs={12}>
                        <Box width='100%' height='100%' ref={parentRef}>
                            {size.width && size.height && (
                                <BarChart
                                    width={size.width}
                                    height={size.height}

                                    series={[
                                        { data: data.map(el => el.costs), label: 'Costs', id: 'Billable', stack: 'total', color: "rgb(46, 150, 255)" },
                                    ]}
                                    xAxis={[{ data: data.map(el => el.month), scaleType: 'band' }]}
                                />)}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}
export default Costs