import { ReactComponent as Silver } from '../../../../../../../assets/Silver.svg'
import { ReactComponent as Gold } from '../../../../../../../assets/Gold.svg'
import { ReactComponent as Bronze } from '../../../../../../../assets/Bronze.svg'
import { Box } from '@mui/material'


const PDA = ({place}) => {
    return (
        <Box sx={{ marginLeft: '5px' }}>
            {place === 1 ?
                <Gold width='30px' height='30px' /> : place === 2 ? <Silver width='30px' height='30px' /> : <Bronze width='30px' height='30px' />
            }
        </Box>
    )
}
export default PDA