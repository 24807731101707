import React, { useEffect, useState } from 'react'
import {
    DialogContent,
    DialogTitle,
    Typography,
    Grid,
    Link,
    TextField,
    Box,
    IconButton,
    Checkbox,
    Autocomplete,
    Button,
} from '@mui/material'
import { KeyboardArrowLeft, Delete, Close } from '@mui/icons-material'
import { ERROR_COLOR, GRAY_BG_COLOR, GRAY_TEXT_COLOR, GREEN_COLOR, ROLE_USER, SECONDARY_TEXT_COLOR, SUBSCRIPTION_PAYMENT_STATUS } from '../../../utils/constants'

import * as USER_REQUESTS from '../../../api/user'
import * as CALENDAR_REQUESTS from '../../../api/calendar'
import FavouritTrainer from '../../BusinessProfile/subcomponents/subcomponents/subcomponents/FavouritTrainer'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const CalendarEventParticipants = ({ checkins, subscriptions, handleGetCheckins, participants, type, eventStart, handleSetAvailableUsers, eventId, access, handleDeleteParticipant, handleAddParticipant, handleClose }) => {

    const { t } = useTranslation()
    const navigate = useNavigate()

    const [users, setUsers] = useState([])
    const [selectedUsers, setSelectedUsers] = useState([])
    const [selectAll, setSelectAll] = useState(false)
    const [filter, setFilter] = useState('')
    const currentTimeDelete = moment();

    useEffect(() => {
        handleGetAllUsers();
    }, [])

    const computeStatus = (subscription, eventStartDate) => {
        let currentTime = new Date().getTime()

        if (eventStartDate) {
            if (subscription.Pause && (moment(eventStartDate).utc().isBetween(moment(subscription.Pause.startDate).utc(), moment(subscription.Pause.endDate).utc(), 'day', '[]'))) {
                return "PAUSED"
            }
        }

        if (!eventStartDate && subscription.Pause && (currentTime >= new Date(subscription.Pause.startDate).getTime() && currentTime <= new Date(subscription.Pause.endDate).getTime())) {
            return "PAUSED"
        }

        if (moment(eventStartDate ? eventStartDate : null).utc().isAfter(moment(subscription.validUntil).utc())) {
            return "EXPIRED"
        }

        if (subscription.status !== SUBSCRIPTION_PAYMENT_STATUS.CONFIRMED) {
            return "INACTIVE"
        }

        return "ACTIVE"
    }

    const handleGetAllUsers = async () => {
        try {
            if (!filter) {
                setUsers([])
                return
            }

            let usersResponse
            if (access !== 'SUBSCRIPTION') {
                usersResponse = await USER_REQUESTS.getAllUsers({ role: ROLE_USER, search: filter })
            }
            else {
                usersResponse = await USER_REQUESTS.getUsersWithSubscription(eventId, filter)
                usersResponse = usersResponse.filter(el => {
                    return el.Subscriptions.filter(sub => {
                        const dayLimit = moment(sub.validFrom).utc().add(sub.Subscription.paymentDeadline - 1, 'days').endOf('day')
                        return subscriptions.map(el => el.id).includes(sub.Subscription.id) && (
                            (computeStatus(sub, eventStart) === "INACTIVE" && (!sub.Subscription.confirmation ? dayLimit.diff(moment(eventStart).utc()) >= 0 && moment(eventStart).utc().date() >= moment(sub.validFrom).utc().date() : false))
                            || (computeStatus(sub, eventStart) === "ACTIVE" && moment(eventStart).utc().diff(moment(sub.validFrom)) > 0 && moment(eventStart).utc().isBefore(moment(sub.validUntil)) && !sub.isDeleted))
                    }).length > 0
                })
            }
            if (usersResponse.length === 1) {
                setUsers(usersResponse)
                handleSetAvailableUsers(usersResponse)
            } else {
                setUsers([])
                handleSetAvailableUsers([])
            }
        } catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {
        handleGetAllUsers()
    }, [filter])

    const handleOnSelectUser = (e) => {
        setSelectedUsers(prevValue => {
            let prevValueCopy = [...prevValue]
            if (e.target.checked) {
                prevValueCopy = prevValueCopy.concat(Number(e.target.value))
            }
            else {
                prevValueCopy = prevValueCopy.filter(el => el !== Number(e.target.value))
            }

            if (prevValueCopy.length === participants.filter(el => !el.Events_Participants.isInWaitingList).length) {
                setSelectAll(true)
            }
            else {
                setSelectAll(false)
            }
            return prevValueCopy
        })
    }

    const handleSelectAll = (e) => {
        setSelectAll(e.target.checked)
        if (e.target.checked) {
            setSelectedUsers(participants.filter(el => !el.Events_Participants.isInWaitingList && !checkins.map(checkin => checkin.userId).includes(el.id)).map(el => el.id))
        }
        else {
            setSelectedUsers([])
        }
    }

    const handleConfirmPresence = async () => {
        try {
            await CALENDAR_REQUESTS.confirmPresenceToEvent(eventId, selectedUsers.filter(el => !checkins.map(checkin => checkin.userId).includes(el)))
            handleGetCheckins()
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <>
            <DialogTitle
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
                p={4}
                pl={{ xs: 2, md: 2, lg: 3 }}
            >
                <Grid container display="flex" flexDirection="row">
                    <Grid item lg={10} xs={10} display="flex" flexDirection="row" alignItems="center">
                        <IconButton onClick={handleClose}> <KeyboardArrowLeft fontSize="medium" /> </IconButton>
                        <Typography variant="h5" fontWeight="bold" pl={2}>
                            {t('calendar.manageParticipants')}
                        </Typography>
                    </Grid>
                    <Grid item lg={2} xs={2} display="flex" flexDirection="row" justifyContent="flex-end">
                        <IconButton style={{ color: 'black' }} onClick={handleClose}>
                            <Close fontSize="medium" color="inherit" />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid pt={2} pb={8} item lg={12} pl={2} pr={2}>
                    <Grid item xs={12} pb={4}>
                        <Grid container display="flex" flexDirection="column">
                            <Grid item xs={12}>
                                <Autocomplete
                                    options={users
                                        .filter((el) => !participants.map(par => par.id).includes(el.id))
                                        .map((el) => ({
                                            label: `${el.firstName} ${el.lastName}`,
                                            avatar: el.avatarPhotoSrc,
                                            email: el.email,
                                            value: el.id,
                                            firstName: el.firstName,
                                            lastName: el.lastName
                                        }))}
                                    autoHighlight
                                    placeholder={t('calendar.searchParticipants')}
                                    renderOption={(props, option) => {
                                        return (
                                            <Box {...props} sx={{ display: 'flex', flexDirection: 'row' }}>
                                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    {option.avatar ? (
                                                        <img
                                                            style={{ borderRadius: 25 }}
                                                            width={50}
                                                            height={50}
                                                            src={option.avatar}
                                                        />
                                                    ) : (
                                                        <Box
                                                            sx={{
                                                                width: 50,
                                                                height: 50,
                                                                backgroundColor: GRAY_BG_COLOR,
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                borderRadius: 25
                                                            }}
                                                        >
                                                            <Typography fontWeight="bold" color={GRAY_TEXT_COLOR}>
                                                                {option.firstName.charAt(0)} {option.lastName.charAt(0)}
                                                            </Typography>
                                                        </Box>
                                                    )}
                                                </Box>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', paddingLeft: 1 }}>
                                                    <Typography fontWeight={500}>{option.label}</Typography>
                                                    <Typography fontWeight={500}>{option.email}</Typography>
                                                </Box>
                                            </Box>
                                        )
                                    }}
                                    onInputChange={(event) => {
                                        setFilter(event.target.value)
                                    }}
                                    filterOptions={(options, state) => options}
                                    onChange={(_, newValue) => {
                                        if (!newValue) return

                                        return handleAddParticipant(newValue.value, null, users)
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            placeholder={t('calendar.searchParticipants')}
                                            {...params}
                                            InputProps={{ ...params.InputProps, endAdornment: null }}
                                            inputProps={{
                                                ...params.inputProps,
                                                style: {
                                                    marginLeft: 6,
                                                    padding: 11.5,
                                                    color: SECONDARY_TEXT_COLOR,
                                                    fontWeight: 500
                                                }
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item pt={4} xs={12}>
                                <Grid container flexWrap="wrap" display="flex">
                                    {participants.filter(el => (type === 'participants' ? !el.Events_Participants.isInWaitingList : el.Events_Participants.isInWaitingList)).map((el, index) => {
                                        return (
                                            <Grid
                                                item
                                                xs={12}
                                                lg={6}
                                                md={6}
                                                pb={2}
                                                key={index}
                                                display="flex"
                                                flexDirection="row"
                                            >
                                                <Grid xs={12} md={12} lg={6} item>
                                                    <Link
                                                        onClick={() => navigate('/clients')}
                                                        style={{ textDecoration: 'none', cursor: 'pointer' }}
                                                        to={'/clients'}
                                                    >
                                                        <FavouritTrainer
                                                            tooltipText={`${el.firstName} ${el.lastName} ${checkins.map(checkin => checkin.userId).includes(el.id) ? t('calendar.with') : t('calendar.without')} ${t('calendar.checkin')}`}
                                                            firstName={el.firstName}
                                                            lastName={el.lastName}
                                                            email={el.email}
                                                            phoneNumber={el.phoneNumber}
                                                            type={'participant'}
                                                            avatarPhotoSrc={el.avatarPhotoSrc}
                                                            border={`3px solid ${checkins.map(checkin => checkin.userId).includes(el.id) ? GREEN_COLOR : ERROR_COLOR}`}
                                                        />
                                                    </Link>
                                                </Grid>

                                                <Grid
                                                    md={4}
                                                    xs={4}
                                                    lg={3}
                                                    item
                                                    display="flex"
                                                    flexDirection="row"
                                                    justifyContent="flex-end"
                                                    pr={3}
                                                >
                                                    {type === 'participants' && <Checkbox disabled={checkins.map(checkin => checkin.userId).includes(el.id)} checked={selectedUsers.includes(el.id) || checkins.map(checkin => checkin.userId).includes(el.id)} value={el.id} onChange={handleOnSelectUser} />}
                                                    {!(moment(currentTimeDelete).isSameOrAfter(moment(eventStart).subtract(3, 'hours')) && type === 'waitingList') &&
                                                        <IconButton onClick={() => handleDeleteParticipant(el.id)} variant="text">
                                                            <Delete sx={{ color: 'black' }} />
                                                        </IconButton>
                                                    }
                                                </Grid>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Grid>
                            {type === 'participants' && <Grid item xs={12} display="flex" flexDirection="row" pl={1} with="100%">
                                <Box pr={2} display="flex" flexDirection="row" alignItems="center">
                                    <Checkbox checked={selectAll} onChange={handleSelectAll} />
                                    <Typography variant="body2">Select All Participants</Typography>
                                </Box>
                                <Box pl={2} display="flex" alignItems="center" justifyContent="center">
                                    <Button onClick={handleConfirmPresence} disabled={selectedUsers.filter(el => !checkins.map(checkin => checkin.userId).includes(el)).length === 0} variant="contained">Confirm Presence For {selectedUsers.filter(el => !checkins.map(checkin => checkin.userId).includes(el)).length} Participants</Button>
                                </Box>
                            </Grid>}
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </>
    )
}

export default CalendarEventParticipants